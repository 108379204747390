import {Image} from "antd";

const LogoDelivery = ({src}) => {

    return <Image
        src={src}
        alt={src}
        style={{  width: '36px', height: '36px', objectFit: 'contain'}}
        preview={false}
    />
}

export default LogoDelivery;