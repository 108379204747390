import Layout from "./layout/layout";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import {getRouting} from "./routing/routing";

function App() {
  const routing = getRouting();
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routing.map((it, index) => {
            return (
                <Route exact path={it.path} key={index} element={it.component} />
            );
          })}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;

// const Error = () => {
//     return (
//         <>
//             <Result
//                 status="warning"
//                 title="Wystąpił błąd podczas wczytywania strony. Skontaktuj się z administratorem"
//                 extra={
//                     <NavLink to={''}>
//                         <Button type="primary" key="console">
//                             Powrót do strony głównej
//                         </Button>
//                     </NavLink>
//                 }
//             />
//         </>
//     );
// }