import React, {useContext} from "react";
import {Pagination} from "antd";
import styled from "styled-components";
import "antd/dist/antd.css";
import {themes} from "../layout/styles/ColorStyles";

const limitOptions = [20, 30, 50, 100];

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const PaginationWrapper = styled(Pagination)`
  .ant-pagination-item {
    :hover {
      border: 1px solid #a9a9a9;
      a {
        color: #a9a9a9;
      }
    }
  }
  .ant-pagination-item-active {
    border: 1px solid ${themes.activeMenuItemColor};

    a {
      color: ${themes.activeMenuItemColor};
    }
  }

  .ant-pagination-item-link {
    :hover {
      border: 1px solid #a9a9a9;
      color: #808080;
      font-weight: bold;
      a {
        color: #a9a9a9;
      }
    }
  }

  .ant-select-item-option-active {
    background-color: #f5f5f5;
  }

  .ant-select-item-option-selected {
    background-color: #e8e8e8;
  }

  .ant-select-selector {
    border: 1px solid #a9a9a9;
    :hover {
      border: 1px solid #808080 !important;
    }
  }
`;

const PaginationList = ({ pagination, FiltersContext, type }) => {
  const { dispatch } = useContext(FiltersContext);

  const handleChange = (page, pageSize) => {
    dispatch({
      type: type,
      payload: { page: page, limit: pageSize },
    });

  };

  return (
    <Wrapper>
      <PaginationWrapper
        showSizeChanger
        defaultCurrent={pagination.page}
        total={pagination.totalItems}
        pageSizeOptions={limitOptions}
        defaultPageSize={pagination.limit}
        locale={{
          items_per_page: "na stronę",
          prev_page: "Poprzednia strona",
          next_page: "Następna strona",
        }}
        onChange={handleChange}
      />
    </Wrapper>
  );
};

export default PaginationList;
