import {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {FaShareSquare, FaUserAlt} from "react-icons/fa";
import {themes} from "../../layout/styles/ColorStyles";
import useAuth from "../../hooks/auth/useAuth";
import AuthContext from "../../context/AuthContext";
import {TooltipContent, TooltipContentItem, TooltipWrapper} from "../../layout/styles/TooltipStyle";

const UserProfileWrapper = styled.div`
  right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${themes.activeMenuItemColor};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  :hover {
    cursor: pointer;
  }
`;


const UserProfile = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef();
  const { logout } = useAuth();
  const { user } = useContext(AuthContext);

  const handleClick = (event) => {
    setShowTooltip(!showTooltip);
    event.preventDefault();
  };

  const handleClickOutside = (event) => {
    if (!tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <UserProfileWrapper onClick={(e) => handleClick(e)}>
        <FaUserAlt />
      </UserProfileWrapper>
      <div ref={tooltipRef}>
        <TooltipWrapper showTooltip={showTooltip}>
          <div className="email">{user.username}</div>
          <TooltipContent>
            <TooltipContentItem>
              <div onClick={logout} className="actions">
                <div className="icon">
                  <FaShareSquare />
                </div>
                <div className="title">Wyloguj</div>
              </div>
            </TooltipContentItem>
          </TooltipContent>
        </TooltipWrapper>
      </div>
    </>
  );
};

export default UserProfile;
