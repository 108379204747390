import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useGetRelatedProductVariants = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);
  const getProductVariant = async (id: string): Promise<any> => {
    try {
      const res = await axiosInstance.get(
        `/partner/product/variant/related/` + id
      );

      if (res.status === 200) {
        setData(res.data ? res.data : []);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return { isLoading, data, getProductVariant };
};

export default useGetRelatedProductVariants;
