import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface NewUserInterface {
  name: string;
  email: string;
  password: string;
  number: string;
  isActive: boolean;
}

const useCreateNewUser = () => {
  const { successMessage, errorMessage } = useAlert();

  const create = async (obj: NewUserInterface): Promise<any> => {
    try {
      await axiosInstance.post("/partner/users/", obj);
      successMessage("Dodano nowego użytkownika");
    } catch (err) {
      errorMessage("Błąd zapisu, spróbuj ponownie.");
      throw err;
    }
  };

  return { create };
};
export default useCreateNewUser;
