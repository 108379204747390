import {useState} from "react";
import axiosInstance from "../../lib/axios";
import {createdAtFrom, createdAtTo,} from "../../reducers/OrderFiltersReducers";

const useGetOrders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);

  const getOrders = async (state: any, hasIndicator = true): Promise<any> => {


    setIsLoading(hasIndicator);
    let modifiedState = {
      ...state,
      createdAtFrom: state[createdAtFrom] ? state[createdAtFrom] : null,
      createdAtTo: state[createdAtTo] ? state[createdAtTo] : null,
    };
    try {
      const res = await axiosInstance.get(`/partner/order/paginated`, {
        params: modifiedState,
      });

      if (res.status === 200) {
        setData(res.data ? res.data : []);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const reload = (filters: any) => {
    getOrders(filters);
  };

  return { isLoading, data, reload, getOrders };
};

export default useGetOrders;
