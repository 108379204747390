import {Select} from "antd";
import status from "../../../../const/status";
import React, {useContext} from "react";
import OrderFiltersContext from "../../../../context/OrderFiltersContext";
import {UPDATE_ORDER_STATUS} from "../../../../reducers/OrderFiltersReducers";

const {Option} = Select;

const OrderStatusSelect = () => {

    const {dispatch, state} = useContext(OrderFiltersContext);

    return  <Select
        defaultValue={state.status}
        showSearch
        placeholder="Wybierz status"
        filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => dispatch({type: UPDATE_ORDER_STATUS, payload: e})}
    >
        {status.map((item) => (
            <Option key={item.value} value={item.value}>
                {item.label}
            </Option>
        ))}
        <Option value="">Wszystkie</Option>
    </Select>
}

export default OrderStatusSelect;