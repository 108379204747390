import styled from "styled-components";
import {themes} from "../../../layout/styles/ColorStyles";
import {useState} from "react";
import {Modal, Typography} from "antd";
import MD5 from "crypto-js/md5";
import useResetUserPassword from "../../../hooks/users/useResetUserPassword";

const Button = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: ${themes.editButton};
  cursor: pointer;
`;

const ChangePasswordModal = ({item}) => {
    const [open, setIsOpen] = useState(false);
    const [password, ] = useState(MD5(new Date().toString()).toString());
    const {update} = useResetUserPassword();

    const handleOk = () => {
        update({userId: item.id, password: password});
        setIsOpen(false);
    }

    return (
        <>
            <Button onClick={() => setIsOpen(true)}>Zmiana hasła</Button>
            <Modal title="Resetowanie hasła" okText={'Zapisz'} cancelText={'Anuluj'} visible={open} onOk={handleOk} onCancel={() => setIsOpen(false)}>
                <Typography.Text>Nowe hasło: <Typography.Text strong>{password}</Typography.Text></Typography.Text>
                <br/>
                <Typography.Text style={{color: themes.switchInActive}}>Aby zapisać hasło wciśnij zapisz.</Typography.Text>
            </Modal>
        </>
    )
}

export default ChangePasswordModal;