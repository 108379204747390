//icons
import Dashboard from "../pages/Dashboards";
import Orders from "../pages/Orders/Orders";
import Deliveries from "../pages/Deliveries/Deliveries";
import OrderDetail from "../pages/Orders/OrderDetail";
import ProductVariants from "../pages/ProductVariants/ProductVariants";
import ProductVariantDetails from "../pages/ProductVariants/ProductVariantDetails";
import Users from "../pages/Users/Users";
import ProductOwnerInformation from "../pages/ProductOwnerInformation/ProductOwnerInformation";
import OrderSettlementInvoiceBills from "../pages/SettlementInvoice/OrderSettlementInvoiceBills";

//jesli jest submenu, ustawiamy path na null

const SettingsRoutes = [
    {
        path: "/",
        name: "Ustawienia konta",
        children: [
            {
                path: "/information",
                name: "Dane sklepu",
                component: <ProductOwnerInformation/>,
            },
            {
                path: "/users",
                name: "Użytkownicy",
                component: <Users/>,
            },
        ],
    },
];

const ProductsRoutes = [
    {
        path: "/",
        name: "Produkty",
        children: [
            {
                path: "/products",
                name: "Lista produktów",
                component: <ProductVariants/>,
                isActive: 1,
                children: null,
            },
        ],
    },
];

const OrdersRoutes = [
    {
        path: "/",
        name: "Zamówienia",
        children: [
            {
                path: "/orders",
                name: "Lista zamówień",
                component: <Orders/>,
                isActive: 1,
                children: null,
            },
        ],
    },
];

const SellSettings = [
    {
        path: "/",
        name: "Ustawienia sprzedaży",
        children: [
            // {
            //     path: "/main-setting",
            //     name: "Ustawienia ogólne",
            //     component: <MainSetting/>,
            //     isActive: 1,
            //     children: null,
            // },
            {
                path: "/deliveries",
                name: "Zarządzanie dostawami",
                component: <Deliveries/>,
                isActive: 1,
                children: null,
            },
        ],
    },
];

// const SettlementsRoutes = [
//     {
//         path: "/",
//         name: "Rozliczenia",
//         children: [
//             {
//                 path: "/order-settlement-invoice",
//                 name: "Transakcje",
//                 component: <OrderSettlementInvoice />,
//                 isActive: 1,
//                 children: null,
//             },
//             // {
//             //     path: "/payment-configuration",
//             //     name: "Konfiguracja płatności",
//             //     component: <PaymentConfigurationInfo />,
//             //     isActive: 1,
//             //     children: null,
//             // },
//         ],
//     },
// ];

const DashboardRoutes = [
    {
        path: "/",
        name: "Nowe zamówienia",
        component: <Dashboard/>,
        isActive: 1,
        children: null,
    },
];

// const SallesRoutes = [
//     {
//         path: "/sales",
//         name: "Raport i analityka",
//         component: <SalesInformation/>,
//         isActive: 1,
//         children: null,
//     },
// ];

// tworzymy tablice dla LeftMenu.jsx
const Routing = [
    ...DashboardRoutes,
    ...OrdersRoutes,
    ...ProductsRoutes,
    ...SellSettings,
    // ...SettlementsRoutes,
    ...SettingsRoutes,
    // ...SallesRoutes
];

/**!!! JESLI CHCEMY DODAĆ ROUTING, ALE NIE CHCEMY DODAWAC GO DO LEFT MENU, DODAJEMY OBIEKT DO TABLICY PONIEJ  */
const routing = [
    {
        path: "/product-variant/:id",
        component: <ProductVariantDetails/>,
    },
    {
        path: "/order/:id",
        component: <OrderDetail/>,
    },
    {
        path: '/order-settlement-invoice-bills/:id',
        component: <OrderSettlementInvoiceBills />,
    }
];

// tworzymy tablice dla routera w App.js
export const getRouting = () => {
    Routing.map((it) => {
        routing.push({path: it.path, component: it.component});
        if (it.children) {
            it.children.map((child) => {
                routing.push({path: child.path, component: child.component});
                return child;
            });
        }

        return it;
    });

    return routing;
};

export default Routing;
