import {Alert, Breadcrumb} from "antd";
import React, {useEffect, useState} from "react";
import useGetDeliveriesForProductOwner from "../../hooks/deliveries/useGetDeliveriesForProductOwner";
import FormIndicator from "../../components/Forms/FormIndicator";
import DeliveriesTable from "../../components/Tables/DeliveriesTable";
import useGetAllDeliveries from "../../hooks/deliveries/useGetAllDeliveries";
import AssignNewDeliveryToProductOwnerModal
  from "../../components/Modals/DeliveryToProductOwner/AssignNewDeliveryToProductOwnerModal";

const Deliveries = () => {
  const { isLoading, data, reload } = useGetDeliveriesForProductOwner();
  const { data: deliveries, isLoading: isLoadingDeliveries } =
    useGetAllDeliveries();
  const [isInfoMessage, setIsInfoMessage] = useState(false);

  useEffect(() => {
    data
      ? setIsInfoMessage(data.every((element) => element.active === false))
      : setIsInfoMessage(false);
  }, [data]);

  return (
    <>
      <Breadcrumb style={{ paddingBottom: "20px" }}>
        <Breadcrumb.Item>Ustawienia sprzedaży</Breadcrumb.Item>
        <Breadcrumb.Item>Dostawy</Breadcrumb.Item>
        <Breadcrumb.Item>Definiowanie cen dostawy</Breadcrumb.Item>
      </Breadcrumb>
      {!isLoading && !isLoadingDeliveries ? (
        <>
          <AssignNewDeliveryToProductOwnerModal
            deliveries={deliveries}
            reload={reload}
          />
          {isInfoMessage || data === null ? (
            <Alert
              style={{ marginBottom: "24px" }}
              showIcon
              message="Aby Twoje oferty były widoczne, co najmniej jedna metoda dostawy musi być dostępna."
              type="warning"
              banner={true}
            />
          ) : null}
          <DeliveriesTable
            data={data}
            reload={reload}
            deliveries={deliveries}
          />
        </>
      ) : (
        <FormIndicator numberOfElements={3} />
      )}
    </>
  );
};

export default Deliveries;
