import {useContext, useState} from "react";
import axiosInstance from "../../lib/axios";
import CounterOrderContext from "../../context/CounterOrderContext";
import {UPDATE_COUNTER} from "../../reducers/CounterOrderReducers";

const useGetOrderCounter = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);
    const {dispatch} = useContext(CounterOrderContext);

    const getCountOrder = async (): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/partner/order/count/`);

            if (res.status === 200) {
                setData(res.data ? res.data : 0);
                setIsLoading(false);
                dispatch({type: UPDATE_COUNTER, payload: res.data})
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    return { isLoading, data, getCountOrder };
}

export default useGetOrderCounter;