import React, { useReducer } from "react";
import {initialState, ProductVariantsFiltersReducer} from "../reducers/ProductVariantsFiltersReducer";

const ProductVariantsFiltersContext = React.createContext();

export const ProductVariantFiltersProvider = ({children}) => {

    const [state, dispatch] = useReducer(ProductVariantsFiltersReducer, initialState);

    return <ProductVariantsFiltersContext.Provider value={{state, dispatch}}>
        {children}
    </ProductVariantsFiltersContext.Provider>
}

export default ProductVariantsFiltersContext;

