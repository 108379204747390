import {useContext, useState} from "react";
import axiosInstance from "../../lib/axios";
import ProductVariantContext from "../../context/ProductVariantContext";
import {SET_ALL} from "../../reducers/ProductVariantReducer";

const useGetProductVariantById = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);
  const { dispatch } = useContext(ProductVariantContext);
  const getProductVariant = async (id: string): Promise<any> => {
    try {
      const res = await axiosInstance.get(
        `/partner/product/variant/show/` + id
      );

      if (res.status === 200) {
        setData(res.data ? res.data : []);
        dispatch({
          type: SET_ALL,
          payload: {
            isActive: res.data.active,
            price: res.data.price[0].priceGross,
            stock: res.data.stock
          },
        });
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return { isLoading, data, getProductVariant };
};

export default useGetProductVariantById;
