import styled from "styled-components";
import {Image, Modal, Switch, Typography} from "antd";
import {themes} from "../../../layout/styles/ColorStyles";
import {DeleteOutlined, DownOutlined, UpOutlined,} from "@ant-design/icons";
import React, {useState} from "react";
import useUpdateLockingProductVariantPhoto from "../../../hooks/productVariants/useUpdateLockingProductVariantPhoto";
import {useParams} from "react-router-dom";
import useUpdateProductVariantPhotoPosition from "../../../hooks/productVariants/useUpdateProductVariantPhotoPosition";
import useRemoveProductVariantPhoto from "../../../hooks/productVariants/useRemoveProductVariantPhoto";
import ImageUploading from "react-images-uploading";
import useAddProductVariantPhoto from "../../../hooks/productVariants/useAddProductVariantPhoto";
import {FaFileImage} from "react-icons/fa";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 0;
`;

const InfoComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
`;

const BigButton = styled.button`
  border: none;
  color: #364a63;
  font-weight: 600;
  height: 150px;
  width: 50%;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease-in;

  :hover {
    background-color: #f8f8f8;
  }
`;

const TopActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
`;

const {Text} = Typography;

const MediaProductVariantTab = ({data, reload}) => {
        const [isPhotoLocked, setIsPhotoLocked] = useState(data.photoLocked);
        const {update} = useUpdateLockingProductVariantPhoto();
        const {update: updatePosition} = useUpdateProductVariantPhotoPosition();
        const {remove} = useRemoveProductVariantPhoto();
        const {update: upload} = useAddProductVariantPhoto();
        const maxNumber = 5;
        const [isModalVisible, setIsModalVisible] = useState(false);

        const params = useParams();

        const handleOk = () => {
            update({isLocked: !isPhotoLocked, productVariantId: params.id}).then(() => {

                    setIsPhotoLocked(!isPhotoLocked)
                    setIsModalVisible(false)
                }
            )
        };

        const handleCancel = () => {
            setIsModalVisible(false);
            setIsPhotoLocked(isPhotoLocked)
        };


        const showModal = (value) => {
            setIsModalVisible(true);
            setIsPhotoLocked(value);
        };


        const handleDeletePhoto = (file) => {
            remove({productVariantId: params.id, photoId: file.photoId}).then(() => reload(params.id));
        };

        const handleChangePosition = (row, position) => {
            updatePosition({
                productVariantId: params.id,
                photoId: row.photoId,
                position: position,
            }).then(() => reload(params.id));
        };

        const uploadPhoto = (imageList, addUpdateIndex) => {

            const fotoItem = imageList[addUpdateIndex];

            const data = new FormData();
            data.append("name", fotoItem.file.name);
            data.append("file", fotoItem.file);

            upload(data, params.id).then(() => reload(params.id));
        };

        return (
            <Wrapper>
                <InfoComponent>
                    <Modal title="Potwierdź zmiany" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <>
                            <Text strong>
                                {!isPhotoLocked ? (
                                    <>
                                        Czy na pewno chcesz zmienić ręcznie pola treści i zablokować je
                                        w synchronizacji danych?
                                    </>
                                ) : (
                                    <>Pola będą synchornizowane autoamtycznie</>
                                )}
                            </Text>
                            <Text type="secondary">
                                {isPhotoLocked ? (
                                    <>
                                        Spowoduje to ustawienie sztywnych wartości i brak ich
                                        aktualizacji podczas synchronizacji danych.
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Text>
                            <Text strong> Pamiętaj aby zapisać dane przyciskiem na dole</Text>
                        </>
                    </Modal>
                    <div>
                        <Switch
                            onClick={(e) => showModal(e)}
                            checked={!isPhotoLocked}
                            style={{
                                width: "20px",
                                marginRight: "5px",
                                backgroundColor: !isPhotoLocked
                                    ? themes.activeMenuItemColor
                                    : themes.switchInActive,
                            }}
                        />
                        <Text>
                            Edytuj zdjęcia ręcznie i zablokuj synchronizację z bazą danych
                        </Text>
                    </div>
                    <Text type="secondary">
                        Jeśli synchronizacja jest zablokowana spowoduje to, że zdjęcia oferty
                        będą wyświetlane według poniżej uzupełnionych pól.
                    </Text>
                </InfoComponent>
                <InfoComponent>
                    <Text>Wszystkie zdjęcia oferty</Text>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "10px",
                        }}
                    >
                        {data.photo.map((file, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                {isPhotoLocked ? (
                                    <></>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            width: "30px",
                                        }}
                                    >
                                        {index === 0 ? (
                                            <></>
                                        ) : (
                                            <UpOutlined
                                                onClick={() => handleChangePosition(file, index - 1)}
                                                style={{
                                                    fontSize: "20px",
                                                    color: themes.switchInActive,
                                                }}
                                            />
                                        )}
                                        <Text>{index + 1}</Text>
                                        {index === data.photo.length - 1 ? (
                                            <></>
                                        ) : (
                                            <DownOutlined
                                                onClick={() => handleChangePosition(file, index + 1)}
                                                style={{
                                                    fontSize: "20px",
                                                    color: themes.switchInActive,
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                                <div
                                    style={{
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        borderColor:
                                            index === 0
                                                ? themes.activeMenuItemColor
                                                : themes.switchInActive,
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {index === 0 ? (
                                            <Text
                                                style={{
                                                    backgroundColor: themes.activeMenuItemColor,
                                                    padding: "0 16px",
                                                }}
                                                strong
                                            >
                                                <>Zdjęcie główne</>
                                            </Text>
                                        ) : (
                                            <div style={{paddingBottom: "16px"}}></div>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{paddingLeft: "16px", paddingBottom: "16px"}}>
                                            <Image
                                                alt="example"
                                                width={100}
                                                height={100}
                                                src={file.fullPath}
                                            />
                                            <Text style={{marginLeft: "10px"}}>{file.name}</Text>
                                        </div>
                                        {isPhotoLocked ? (
                                            <></>
                                        ) : (
                                            <DeleteOutlined
                                                onClick={() => handleDeletePhoto(file)}
                                                style={{
                                                    fontSize: "20px",
                                                    color: themes.switchInActive,
                                                    marginRight: "10px",
                                                    paddingBottom: "16px",
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </InfoComponent>
                {isPhotoLocked ? <></> : <ImageUploading
                    maxNumber={maxNumber}
                    dataURLKey="src"
                    value={data.photo ? data.photo : []}
                    onChange={uploadPhoto}>
                    {({
                          onImageUpload,
                          isDragging,
                          dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <TopActionsWrapper>
                                <BigButton
                                    style={isDragging ? {color: "red"} : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                >
                                    <FaFileImage
                                        style={{fontSize: "40px", color: "#ffffff"}}
                                    />
                                    Wybierz lub przeciągnij plik tutaj
                                </BigButton>
                            </TopActionsWrapper>
                            {/*{photos ?*/}
                            {/*    <ProductMediaList data={photos} handleChangePosition={handleChangePosition}*/}
                            {/*                      handleRemove={handleRemove}/> : null}*/}
                        </div>
                    )}

                </ImageUploading>}
            </Wrapper>
        );
    }
;
export default MediaProductVariantTab;
