import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateUserPassword {
    userId: string;
    password: string;
}

const useResetUserPassword = () => {

    const { successMessage, errorMessage } = useAlert();

    const update = async (obj: UpdateUserPassword): Promise<any> => {
        try {
            await axiosInstance.put("/partner/users/password/", obj);
            successMessage("Zmieniono hasło");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie.");
            throw err;
        }
    };

    return { update };
};

export default useResetUserPassword;