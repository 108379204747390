import {Select} from "antd";
import React, {useContext} from "react";
import OrderFiltersContext from "../../../../context/OrderFiltersContext";
import {UPDATE_PAYMENT_STATUS} from "../../../../reducers/OrderFiltersReducers";

const OrderPaymentStatus = () => {

    const {dispatch, state} = useContext(OrderFiltersContext);

    return <Select mode="single" placeholder="Wybierz rodzaj płatności"
                   defaultValue={state.statusPayment}
                   onChange={(e) => dispatch({type: UPDATE_PAYMENT_STATUS, payload: e})}>
        <Select.Option key={0} value={null}>
            Wszystkie
        </Select.Option>
        <Select.Option key={1} value={1}>
            Opłacone
        </Select.Option>
        <Select.Option key={2} value={0}>
            Nie opłacone
        </Select.Option>
    </Select>
}

export default OrderPaymentStatus;