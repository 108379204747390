import useGetAllBrands from "../../hooks/brands/useGetAllBrands";
import useGetProductVariantsCollection from "../../hooks/productVariants/useGetProductVariantsCollection";
import ProductVariantsFiltersContext from "../../context/ProductVariantsFiltersContext";
import {useContext, useEffect} from "react";
import FilterCollapse from "../../components/Collaps/FilterCollapse";
import FilterProductVariant from "../../components/Forms/ProductVariant/FilterProductVariant";
import PaginationList from "../../components/PaginationList";
import {CHANGE_PAGE_AND_LIMIT} from "../../reducers/ProductVariantsFiltersReducer";
import {Breadcrumb} from "antd";
import FormIndicator from "../../components/Forms/FormIndicator";
import useGetAllCategories from "../../hooks/categories/useGetAllCategories";
import ProductVariantTable from "../../components/Tables/ProductVariantTable";

const ProductVariants = () => {
    const {data: brands, isLoading: isBrands} = useGetAllBrands();
    const {data: categories, isLoading: isCategories} = useGetAllCategories();
    const {data, isLoading, reload} = useGetProductVariantsCollection();
    const {state} = useContext(ProductVariantsFiltersContext);

    useEffect(() => {
        reload(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.limit]);

    return (
        <>
            <Breadcrumb style={{paddingBottom: "20px"}}>
                <Breadcrumb.Item>Panel sprzedawcy</Breadcrumb.Item>
                <Breadcrumb.Item>Lista produktów</Breadcrumb.Item>
            </Breadcrumb>
            {!isBrands && !isCategories ? (
                <FilterCollapse
                    children={
                        <FilterProductVariant brands={brands} categories={categories} reload={() => reload(state)}/>
                    }
                />
            ) : (
                <FormIndicator numberOfElements={1}/>
            )}
            {!isLoading ? (
                <>
                    <ProductVariantTable
                        data={data.results}
                        reload={() => reload(state)}
                    />
                    <PaginationList
                        reload={() => reload(state)}
                        pagination={data.pagination}
                        FiltersContext={ProductVariantsFiltersContext}
                        type={CHANGE_PAGE_AND_LIMIT}
                    />
                </>
            ) : (
                <FormIndicator numberOfElements={2}/>
            )}
        </>
    );
};

export default ProductVariants;
