import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

interface RemoveProductVariantPhotoProps {
  productVariantId: string;
  photoId: string;
}

const useRemoveProductVariantPhoto = () => {
  const { successMessage, errorMessage } = useAlert();
  const remove = async (obj: RemoveProductVariantPhotoProps): Promise<any> => {
    try {
      await axiosInstance.delete(
        "/partner/product/variant/" +
          obj.productVariantId +
          "/" +
          obj.photoId +
          "/remove-photo"
      );
      successMessage("Twoje zdjęcie zostanie wkrótce usunięte");
    } catch (err) {
      errorMessage("Błąd zapisu. Spróbuj ponownie.");
      throw err;
    }
  };

  return { remove };
};

export default useRemoveProductVariantPhoto;
