import {useContext} from "react";
import {Input} from "antd";
import OrderFiltersContext from "../../../../context/OrderFiltersContext";
import {UPDATE_CLIENT_SEARCH} from "../../../../reducers/OrderFiltersReducers";

const ClientSearch = () => {

    const {dispatch, state} = useContext(OrderFiltersContext);

    return <Input defaultValue={state.client}
                  onBlur={(e) => dispatch({type: UPDATE_CLIENT_SEARCH, payload: e.target.value})}/>
}

export default ClientSearch