import {Select} from "antd";
import React, {useContext} from "react";
import ProductVariantsFiltersContext from "../../../context/ProductVariantsFiltersContext";

const IsBlockedSelect = ({type, test}) => {

    const {dispatch} = useContext(ProductVariantsFiltersContext);

    return <Select mode="single" allowClear={true} defaultValue={test}
                   onChange={(e) => {
                       dispatch({type: type, payload: e})
                   }}>
        <Select.Option key={0} value={null}>
            Wszystkie
        </Select.Option>
        <Select.Option key={1} value={1}>
            Tak
        </Select.Option>
        <Select.Option key={2} value={0}>
            Nie
        </Select.Option>
    </Select>
}

export default IsBlockedSelect;