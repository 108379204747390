import {useContext} from "react";
import {Input} from "antd";
import OrderFiltersContext from "../../../../context/OrderFiltersContext";
import {UPDATE_DELIVERY_NUMBER} from "../../../../reducers/OrderFiltersReducers";

const TrackingNumberSerach = () => {

    const {dispatch, state} = useContext(OrderFiltersContext);

    return <Input defaultValue={state.trackingNumber}
                  onBlur={(e) => dispatch({type: UPDATE_DELIVERY_NUMBER, payload: e.target.value})}/>
}

export default TrackingNumberSerach;