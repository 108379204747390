import {CounterOrderReducer, initialState} from "../reducers/CounterOrderReducers";
import React, {useReducer} from "react";

const CounterOrderContext = React.createContext();

export const CounterOrderProvider = ({children}) => {
    const [state, dispatch] = useReducer(CounterOrderReducer, initialState);

    return <CounterOrderContext.Provider value={{state, dispatch}}>
        {children}
    </CounterOrderContext.Provider>
}

export default CounterOrderContext;