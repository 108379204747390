import {useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import ProductVariantContext from "../../../context/ProductVariantContext";
import useUpdateProductVariantLockStock from "../../../hooks/productVariants/useUpdateProductVariantLockStock";
import {Input, Modal, Switch, Typography} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {themes} from "../../../layout/styles/ColorStyles";
import {SET_STOCK} from "../../../reducers/ProductVariantReducer";

const {Text} = Typography;

const ProductVariantMainSettingStockInput = ({data, reload}) => {

    const params = useParams();
    const [isLocked, setIsLocked] = useState(data.isStockLocked);
    const [stock, setStock] = useState(data.stock);

    const {update} = useUpdateProductVariantLockStock();
    const {dispatch} = useContext(ProductVariantContext);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (value) => {
        setIsModalVisible(true);
        setStock(value);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        dispatch({type: SET_STOCK, payload: stock});
        update({
            productVariantId: params.id,
            stock: parseInt(stock),
            isLocked: isLocked,
        })
            .then(() => reload(params.id))
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (e) => {
        setIsLocked(e);
        if (!e) {
            update({
                productVariantId: params.id,
                stock: parseInt(stock),
                isLocked: e,
            }).then(() => reload(params.id));
        }
    };

    return (
        <>
            <Text>Stan magazynowy</Text>
            <Modal title="Potwierdź zmiany" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <><Text strong>
                    Czy na pewno chcesz zmienić stan magazynowy i zablokować to pole w
                    synchronizacji danych?{" "}
                </Text>
                    <Text type="secondary">
                        {" "}
                        Spowoduje to ustawienie sztywnej wartości stanu magazynowego ręcznie i brak aktualizacji
                        podczas synchronizacji danych.
                    </Text></>
            </Modal>
            <div style={{display: "flex", gap: "8px"}}>
                <Switch
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                    checked={isLocked}
                    onChange={(e) => handleChange(e)}
                    style={{
                        display: "flex",
                        width: "20px",
                        backgroundColor: isLocked
                            ? themes.activeMenuItemColor
                            : themes.switchInActive,
                    }}
                />
                <Text>Edytuj stan magazynowy ręcznie i zablokuj synchronizację z bazą danych</Text>
            </div>
            {!isLocked ? (
                <Text strong>{data.stock}</Text>
            ) : (
                <Input
                    type="number"
                    defaultValue={data.stock}
                    style={{width: "200px", marginBottom: "4px"}}
                    onBlur={(e) => showModal(e.target.value)}
                />
            )}
        </>
    );
}

export default ProductVariantMainSettingStockInput;