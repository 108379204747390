import styled from "styled-components";
import {Button, Input, Modal, Switch, Typography} from "antd";
import React, {useState} from "react";
import "react-quill/dist/quill.snow.css";
import {themes} from "../../../layout/styles/ColorStyles";
import useUpdateLockingProductVariantDescription
    from "../../../hooks/productVariants/useUpdateLockingProductVariantDescription";
import {useParams} from "react-router-dom";
import MyEditor from "./MyEditor";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 0;
`;

const InfoComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
`;

const { Text } = Typography;

const ContentProductVariant = ({ data }) => {
  const params = useParams();
  const [name, setName] = useState(data.name);
  const [value, setValue] = useState(data.description);
  const [isDescriptionLocked, setIsDescriptionLocked] = useState(
    data.isDescriptionLocked
  );

    const [isModalVisible, setIsModalVisible] = useState(false);

  const { update } = useUpdateLockingProductVariantDescription();

  const handleMultiple = () => {
    update({
      isLocked: isDescriptionLocked,
      isMultiSave: true,
      description: value,
      name: name,
      productVariantId: params.id,
    });
  };

  const handleOne = () => {
    update({
      isLocked: isDescriptionLocked,
      isMultiSave: false,
      description: value,
      name: name,
      productVariantId: params.id,
    });
  };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsDescriptionLocked(isDescriptionLocked)
    };


    const showModal = (value) => {
        setIsModalVisible(true);
        setIsDescriptionLocked(value);
    };


  return (
    <Wrapper>
      <InfoComponent>
        <div>
            <Modal title="Potwierdź zmiany" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <>
                    <Text strong>
                        {isDescriptionLocked ? (
                            <>
                                Czy na pewno chcesz zmienić ręcznie pola treści i zablokować je
                                w synchronizacji danych?
                            </>
                        ) : (
                            <>Pola będą synchornizowane autoamtycznie</>
                        )}
                    </Text>
                    <Text type="secondary">
                        {isDescriptionLocked ? (
                            <>
                                Spowoduje to ustawienie sztywnych wartości i brak ich
                                aktualizacji podczas synchronizacji danych.
                            </>
                        ) : (
                            <></>
                        )}
                    </Text>
                    <Text strong> Pamiętaj aby zapisać dane przyciskiem na dole</Text>
                </>
            </Modal>
          <Switch
            checked={isDescriptionLocked}
            onChange={(e) => showModal(e)}
            style={{
              width: "20px",
              marginRight: "5px",
              backgroundColor: isDescriptionLocked
                ? themes.activeMenuItemColor
                : themes.switchInActive,
            }}
          />
          <Text>
            Edytuj pola ręcznie i zablokuj synchronizację z bazą danych
          </Text>
        </div>
        <Text type="secondary">
          Jeśli synchronizacja jest zablokowana spowoduje to, że treści oferty
          będą wyświetlane według poniżej uzupełnionych pól.
        </Text>
      </InfoComponent>
      <InfoComponent>
        <Text>Nazwa wariantu</Text>
        {!isDescriptionLocked ? (
          <Text strong>{name}</Text>
        ) : (
          <Input
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}
      </InfoComponent>
      <InfoComponent>
        <Text>Opis produktu</Text>
        {!isDescriptionLocked ? (
          <Text strong>{data.description}</Text>
        ) : (
          <MyEditor setValue={setValue} value={value} />
        )}
      </InfoComponent>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button type="primary" onClick={() => handleMultiple()}>
          Zastosuj dla wszystkich wariantów tego produktu
        </Button>
        <Button type="primary" onClick={() => handleOne()}>
          Zastosuj dla tego wariantu
        </Button>
      </div>
    </Wrapper>
  );
};

export default ContentProductVariant;
