import styled from "styled-components";
import {Image, Switch, Typography} from "antd";
import useUpdateActivityForProductVariants from "../../../hooks/productVariants/useUpdateActivityForProductVariants";
import {themes} from "../../../layout/styles/ColorStyles";
import React, {useContext} from "react";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import ProductVariantContext from "../../../context/ProductVariantContext";
import {SET_ACTIVE} from "../../../reducers/ProductVariantReducer";
import ProductVariantMainSettingPriceInput from "../../Input/ProductVaraint/ProductVariantMainSettingPriceInput";
import ProductVariantMainSettingStockInput from "../../Input/ProductVaraint/ProductVariantMainSettingStockInput";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 0;
`;

const InfoComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
`;

const BoldText = styled(Typography.Text)`
  font-weight: bold;
`;

const {Text} = Typography;

const MainSettingProductVariantTab = ({data, reload}) => {
    const {update} = useUpdateActivityForProductVariants();
    const {dispatch} = useContext(ProductVariantContext);

    return (
        <Wrapper>
            <InfoComponent>
                <Text>Aktywność oferty</Text>
                <Switch
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                    style={{
                        width: "20px",
                        backgroundColor: data.active
                            ? themes.activeMenuItemColor
                            : themes.switchInActive,
                    }}
                    defaultChecked={data.active}
                    onChange={(e) => {
                        dispatch({type: SET_ACTIVE, payload: e});
                        update({productVariantIds: [data.id], active: e}).then(() =>
                            reload()
                        );
                    }}
                />
            </InfoComponent>
            <InfoComponent>
                <Text>Główne zdjęcie oferty</Text>
                <Image
                    height={120}
                    width={100}
                    src={
                        data.photo.length && typeof data.photo[0].fullPath !== "undefined"
                            ? data.photo[0].fullPath
                            : "../graphic-placeholder.png"
                    }
                />
            </InfoComponent>
            <InfoComponent>
                <Text>Nazwa Oferty</Text>
                <BoldText>{data.name}</BoldText>
            </InfoComponent>
            <InfoComponent>
                <Text>Wariant produktu</Text>
                <BoldText>
                    {data.varaintValue && data.grammage
                        ? data.varaintValue + " " + data.grammage
                        : "Brak danych"}
                </BoldText>
            </InfoComponent>
            <InfoComponent>
                <Text>Producent</Text>
                {data.brand ? <Image height={120}
                                     width={100} src={data.brand.logo}/> : 'Brak danych'}
            </InfoComponent>
            <InfoComponent>
                <ProductVariantMainSettingStockInput data={data} reload={reload}/>
            </InfoComponent>
            <InfoComponent>
                <ProductVariantMainSettingPriceInput data={data} reload={reload}/>
            </InfoComponent>
            <InfoComponent>
                <Text>Stawka Vat</Text>
                <BoldText>{data.price[0].vatRate.value} %</BoldText>
            </InfoComponent>
            <InfoComponent>
                <Text>Id Produktu</Text>
                <BoldText>{data.id}</BoldText>
            </InfoComponent>
            <InfoComponent>
                <Text>Ean Produktu</Text>
                <BoldText>{data.ean}</BoldText>
            </InfoComponent>
            <InfoComponent>
                <Text>Kategorie produktu</Text>
                {data.categories.length
                    ? data.categories.map((category) => (
                        <BoldText key={category.id}>{category.fullPath}</BoldText>
                    ))
                    : null}
            </InfoComponent>
        </Wrapper>
    );
};

export default MainSettingProductVariantTab;
