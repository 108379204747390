import {Button, Image, Switch, Table, Tooltip} from "antd";
import {themes} from "../../layout/styles/ColorStyles";
import {CheckOutlined, CloseOutlined, LockOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import useUpdateActivityForProductVariants from "../../hooks/productVariants/useUpdateActivityForProductVariants";
import {Link} from "react-router-dom";

const ProductVariantTable = ({ data, reload }) => {
  const columns = [
    {
      title: "Aktywność",
      dataIndex: "",
      key: "active",
      render: (row) => (
        <Switch
          style={{
            backgroundColor: row.active
              ? themes.activeMenuItemColor
              : themes.switchInActive,
          }}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={row.active}
          onChange={(e) => {
            update({ productVariantIds: [row.id], active: e }).finally(() => {
              reload();
            });
          }}
        />
      ),
    },
    {
      title: "Produkt",
      dataIndex: "",
      key: "icon",
      render: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
           <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
             {!row.isDescriptionLocked ? (
              <Tooltip title="Zdjęcia zablokowane">
                <LockOutlined style={{ color: themes.activeMenuItemColor }} />
              </Tooltip>
            ) : (
              ""
            )}
             <Image
              width={52}
              height={52}
              preview={true}
              src={
                row.photo.length && typeof row.photo[0].fullPath !== "undefined"
                  ? row.photo[0].fullPath
                  : "graphic-placeholder.png"
              }
            />
             <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
               <div>
                 <div style={{ fontWeight: "600" }}>{row.name}</div>
                 <div>{row.brand ? row.brand.name : 'brak producenta'}</div>
               </div>
             </div>
           </div>
           {!row.isDescriptionLocked ? (
             <Tooltip title="Opis zablokowany">
               <LockOutlined style={{ color: themes.activeMenuItemColor }} />
             </Tooltip>
           ) : (
             ""
           )}
        </div>
      ),
    },
    {
      title: "Zewnętrzne ID Produktu",
      dataIndex: "",
      key: "externalId",
      render: (row) => (
        <div>
          <div>{row.id}</div>
          Ean: {row.ean}
        </div>
      ),
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
    },
    {
      title: "Wariant",
      dataIndex: "",
      key: "variant",
      render: (row) => (
        <>
          {row.grammage && row.variantValue ? (
            <>
              {row.variantValue}
              {row.grammage}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Cena Brutto",
      dataIndex: "price",
      key: "price",
      width: "10%",
      render: (row) =>
        row.length ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {row[0].priceGross + " zł"}
            {!row.locked ? (
              <></>
            ) : (
              <Tooltip
                color="black"
                placement="top"
                title="Oferta zawiera blokadę synchronizacji cen"
              >
                <LockOutlined style={{ color: themes.activeMenuItemColor }} />
              </Tooltip>
            )}
          </div>
        ) : (
          "Brak ceny"
        ),
    },
    {
      title: "Akcja",
      dataIndex: "",
      key: "edit",
      render: (row) => <Link to={"/product-variant/" + row.id}>Szczegóły</Link>,
    },
  ];

  const { update } = useUpdateActivityForProductVariants();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      name: record.id,
    }),
  };

  const handleSetActive = () => {
    update({ productVariantIds: selectedRowKeys, active: true }).then(() =>
      reload()
    );
  };

  const handleSetInActive = () => {
    update({ productVariantIds: selectedRowKeys, active: false }).then(() =>
      reload()
    );
  };

  return (
    <>
      <div style={{ marginTop: "10px", display: "flex", gap: "5px" }}>
        <Button onClick={() => handleSetActive()}>Włącz aktywność</Button>
        <Button onClick={() => handleSetInActive()}>Wyłącz aktywność</Button>
      </div>
      <Table
        rowSelection={rowSelection}
        style={{ marginTop: "10px" }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </>
  );
};

export default ProductVariantTable;
