import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateProductVariantPhotoPositionInterface {
  productVariantId: string;
  photoId: string;
  position: number;
}

const useUpdateProductVariantPhotoPosition = () => {
  const { successMessage, errorMessage } = useAlert();
  const update = async (
    obj: UpdateProductVariantPhotoPositionInterface
  ): Promise<any> => {
    try {
      await axiosInstance.put(
        "/partner/product/variant/" + obj.productVariantId + "/position-photo",
        obj
      );
      successMessage("Pozycja zdjęcia została zmieniona");
    } catch (err) {
      errorMessage("Błąd zapisu. Spróbuj ponownie.");
      throw err;
    }
  };

  return { update };
};

export default useUpdateProductVariantPhotoPosition;
