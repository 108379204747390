export const CHANGE_PAGE = "change_page";
export const CHANGE_LIMIT = "change_limit";
export const CHANGE_PAGE_AND_LIMIT = "change_page_and_limit";
export const UPDATE_ORDER_NUMBER = 'update_order_number';
export const UPDATE_DATE_FROM = 'update_date_from';
export const UPDATE_DATE_TO = 'update_date_to';
export const UPDATE_PRICE_FROM = 'update_price_from';
export const UPDATE_PRICE_TO = 'update_price_to';
export const UPDATE_ORDER_STATUS = 'update_order_status';
export const UPDATE_CLIENT_SEARCH = 'update_client_search';
export const UPDATE_DELIVERY_NUMBER = 'update_delivery_number';
export const UPDATE_PAYMENT_STATUS = 'update_payment_status';
export const UPDATE_DELIVERY_ID = 'update_delivery_id';

export const UPDATE_ALL = "update_all";
export const CLEAR_ALL = "clear_all";

export const priceFrom = "priceFrom";
export const priceTo = "priceTo";
export const limit = "limit";
export const page = "page";
export const client = "client";
export const createdAtTo = "createdAtTo";
export const createdAtFrom = "createdAtFrom";
export const status = "status";
export const orderNumber = "orderNumber";
export const trackingNumber = "trackingNumber";
export const statusPayment = "statusPayment";
export const deliveryId = "deliveryId";

export const initialState = {
    [limit]: 20,
    [page]: 1,
    [client]: "",
    [priceFrom]: "",
    [priceTo]: "",
    [createdAtFrom]: "",
    [createdAtTo]: "",
    [status]: "",
    [orderNumber]: '',
    [trackingNumber]: "",
    [statusPayment]: null,
    [deliveryId]: "",
};

type ACTIONTYPE =
    | { type: typeof UPDATE_ORDER_NUMBER; payload: number }
    | { type: typeof UPDATE_DATE_FROM; payload: string }
    | { type: typeof UPDATE_DATE_TO; payload: string }
    | { type: typeof UPDATE_PRICE_FROM; payload: string }
    | { type: typeof UPDATE_PRICE_TO; payload: string }
    | { type: typeof UPDATE_ORDER_STATUS; payload: string }
    | { type: typeof UPDATE_CLIENT_SEARCH; payload: string }
    | { type: typeof UPDATE_DELIVERY_NUMBER; payload: string }
    | { type: typeof UPDATE_PAYMENT_STATUS; payload: string }
    | { type: typeof UPDATE_DELIVERY_ID; payload: string }
    | { type: typeof CHANGE_PAGE; payload: number }
    | { type: typeof CHANGE_LIMIT; payload: number }
    | {
    type: typeof CHANGE_PAGE_AND_LIMIT;
    payload: { [page]: number; [limit]: number };
}
    | { type: typeof CLEAR_ALL; payload: null };


export const OrderFilterReducers = (
    state: typeof initialState,
    action: ACTIONTYPE
) => {
    switch (action.type) {
        case CHANGE_PAGE:
            return {
                ...state,
                [page]: action.payload,
            };
        case CHANGE_LIMIT:
            return {
                ...state,
                [limit]: action.payload,
            };
        case CHANGE_PAGE_AND_LIMIT:
            return {
                ...state,
                [page]: action.payload[page],
                [limit]: action.payload[limit],
            };

        case CLEAR_ALL:
            return initialState;

        case UPDATE_ORDER_NUMBER:
            return {
                ...state,
                [orderNumber]: action.payload
            }

        case UPDATE_DATE_FROM:
            return {
                ...state,
                [createdAtFrom]: action.payload,
            };
        case UPDATE_DATE_TO:
            return {
                ...state,
                [createdAtTo]: action.payload,
            };

        case UPDATE_PRICE_FROM:
            return {
                ...state,
                [priceFrom]: action.payload,
            };

        case UPDATE_PRICE_TO:
            return {
                ...state,
                [priceTo]: action.payload,
            };

        case UPDATE_ORDER_STATUS:
            return {
                ...state,
                [status]: action.payload
            }
        case UPDATE_CLIENT_SEARCH:
            return {
                ...state,
                [client]: action.payload
            }

        case UPDATE_DELIVERY_NUMBER:
            return {
                ...state,
                [trackingNumber]: action.payload
            }

        case UPDATE_PAYMENT_STATUS:
            return {
                ...state,
                [statusPayment]: action.payload
            }

        case UPDATE_DELIVERY_ID:
            return {
                ...state,
                [deliveryId]: action.payload
            }

        default:
            return state;
    }
};
