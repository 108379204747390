export const price = "price";
export const isActive = "isActive";
export const stock = 'stock';

export const SET_ALL = "set_all";
export const SET_PRICE = "set_price";
export const GET_ALL = "get_all";
export const SET_ACTIVE = "set_active";
export const SET_STOCK = 'set_stock';

export const initialState = {
    [price]: 0,
    [isActive]: null,
    [stock]: 0,
};

type ACTIONTYPE =
    | { type: typeof SET_ACTIVE; payload: { isActive: boolean } }
    | { type: typeof SET_PRICE; payload: number }
    | { type: typeof SET_STOCK; payload: number }
    | {
    type: typeof GET_ALL;
    payload: {
        [price]: number;
        [isActive]: boolean;
        [stock]: number;
    };
}
    | { type: typeof SET_ALL; payload: { [price]: number; [isActive]: boolean, [stock]: number } };

export const ProductVariantReducer = (
    state = initialState,
    action: ACTIONTYPE
) => {
    switch (action.type) {
        case SET_ALL: {
            return {
                [isActive]: action.payload.isActive,
                [price]: action.payload.price,
                [stock]: action.payload.stock
            };
        }
        case SET_ACTIVE: {
            return {...state, [isActive]: action.payload};
        }
        case SET_PRICE:
            return {
                ...state,
                [price]: action.payload,
            };
        case SET_STOCK:
            return {
                ...state,
                [stock]: action.payload,
            };
        case GET_ALL:
            return {
                ...state,
            };
        default:
            return state;
    }
};
