import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetProductVariantsCollection = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);

  const getProductVariants = async (state: any): Promise<any> => {
    try {
      const res = await axiosInstance.get(`/partner/product/variant/`, {
        params: state,
      });

      if (res.status === 200) {
        setData(res.data ? res.data : []);
        setIsLoading(false);
      }
    } catch (err) {
      window.location.replace('/500');
      setIsLoading(false);
    }
  };

  const reload = (filters: any) => {
    getProductVariants(filters);
  };

  return { isLoading, data, reload, setIsLoading };
};

export default useGetProductVariantsCollection;
