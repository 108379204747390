import useGetSettlementInvoiceBills from "../../hooks/settlementInvoice/useGetSettlementInvoiceBills";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import FormIndicator from "../../components/Forms/FormIndicator";
import {Title} from "../../layout/styles/ModalStyle";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Breadcrumb, Typography} from "antd";
import OrderSettlementInvoiceBillTable
    from "../../components/Tables/OrderSettlementInvoice/OrderSettlementInvoiceBillTable";

const OrderSettlementInvoiceBills = () => {

    const {data, isLoading, getOrderSettlementBills} = useGetSettlementInvoiceBills();
    const params = useParams();

    useEffect(() => {
        getOrderSettlementBills(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <Breadcrumb style={{paddingBottom: "24px"}}>
            <Breadcrumb.Item>Profil sprzedawcy</Breadcrumb.Item>
            <Breadcrumb.Item>Rozliczenia </Breadcrumb.Item>
            <Breadcrumb.Item>Transakcje </Breadcrumb.Item>
            <Breadcrumb.Item>Wszystkie transakcję</Breadcrumb.Item>
        </Breadcrumb>
        <Title>
            <div onClick={() => window.history.go(-1)}>
                <ArrowLeftOutlined/> <Typography.Text strong>Wróc do listy transakcji wg miesiąca</Typography.Text>
            </div>
        </Title>
        {isLoading ? <FormIndicator numberOfElements={1}/> : <>
            <OrderSettlementInvoiceBillDetails data={data}/>
            <OrderSettlementInvoiceBillTable data={data} />
        </>}
        </>;
}

export default OrderSettlementInvoiceBills;

const OrderSettlementInvoiceBillDetails = ({data}) => {

    const getPrice = () => {
        return data.map((it) => parseInt(it.orderCommission)).reduce((a, b) => a + b, 0);
    }

    return <div style={{backgroundColor: 'white', display: 'flex', flexDirection: 'row', marginBottom: '10px'}}>
        <div style={{ display: 'flex', width: '30%', flexDirection: 'column', padding: '15px'}}>
            <Typography>
                Kwota prowizji dla dat
            </Typography>
            <div style={{font: 'Roboto', fontWeight: '500', fontSize: '38px'}}>
                {getPrice()} zł
            </div>
        </div>

        <div style={{display: 'flex', width: '30%', flexDirection: 'column', padding: '15px'}}>
            <Typography>Ilość transakcji</Typography>
            <div  style={{font: 'Roboto', fontWeight: '500', fontSize: '38px'}}>
                {data.length}
            </div>
        </div>

    </div>;
}