import {Image, Table} from "antd";
import {useEffect, useState} from "react";
import styled from "styled-components";
import productOwnerStorage from "../../../lib/productOwnerStorage";
import {LinkOutlined} from "@ant-design/icons";

const ContentPriceDetail = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const SummaryOrderDetail = styled(ContentPriceDetail)`
  font-size: 16px;
  font-weight: 700;
`;

const OrderItemsTable = ({ orderItems, data }) => {
    const [items, setOrderItems] = useState([]);
    const {getProductOwnerName} = productOwnerStorage;
    const [isDiscount, setIsDiscount ] = useState();

    useEffect(() => {
        let test = orderItems.map((it, index) => {
            return {
                key: index,
                ...it,
            };
        });

        setOrderItems(test);
        setIsDiscount(data.discount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: "Zamówienie",
            dataIndex: "",
            key: "product",
            render: (row) => (
                <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                    <Image preview={true} src={row.productVariant.photos.length ? row.productVariant.photos[0].fullPath : row.product.photos[0]}
                           style={{width: '110px', height: '110px', objectFit: 'contain'}}/>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                        }}
                    >
                        <div>
                            <div style={{ fontWeight: "600" }}> {row.productVariant.name}</div>
                            <LinkOutlined
                                onClick={() =>
                                    window.open(
                                        process.env.REACT_APP_MAIN+'/product/'+
                                        getProductOwnerName() +
                                        "/" +
                                        row.productVariant.slug,
                                        "_blank"
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            ),
            width: "50%",
        },
        {
            title: "Producent",
            dataIndex: "",
            key: "productVariant",
            render: (row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {row.product.brand ? row.product.brand.name : 'Brak producenta'}
                </div>
            ),
        },
        // {
        //     title: "Cena netto produktu",
        //     dataIndex: "",
        //     key: "priceNet",
        //     render: (row) => (
        //         <div style={{ display: "flex", justifyContent: "center" }}>
        //             {row.priceNet} zł
        //         </div>
        //     ),
        // },
        {
            title: "Cena brutto produktu",
            dataIndex: "",
            key: "priceGross",
            render: (row) => (
                <div style={{display: "flex", justifyContent: "center", flexDirection: 'column'}}>
                    {
                        isDiscount && row.discount && data.discount.type !== 'FREE_SHIPPING' ? <div style={{textDecoration: 'line-through'}}>{Math.round((row.originalPriceGross / row.quantity) * 100) / 100} zł</div> : ''
                    }

                    {Math.round((row.priceGross / row.quantity) * 100) / 100} zł
                </div>
            ),
        },
        {
            title: "Ilość",
            dataIndex: "quantity",
            key: "quantity",
            render: (quantity) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {quantity}x
                </div>
            ),
        },
        {
            title: "Wartość netto",
            dataIndex: "",
            key: "priceGross",
            render: (row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {row.priceNet} zł
                </div>
            ),
        },
        {
            title: "Wartość brutto",
            dataIndex: "",
            key: "priceGross",
            render: (row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {row.priceGross} zł
                </div>
            ),
        },
    ];

    return (
        <>
            <Table columns={columns} dataSource={items} pagination={false} />
            <div
                style={{
                    backgroundColor: "white",
                    height: "136px",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "60%",
                        alignItems: "center",
                        paddingLeft: "20px",
                    }}
                >

                    {
                        data.discount && orderItems[0].discount && data.discount.type !== 'FREE_SHIPPING' ? <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>Aktywowane rabaty:</div>
                            <div style={{fontWeight: 700}}>{data.discount.code}</div> ({data.discount.discountValueFormatted})
                            <div style={{color: 'grey'}}>
                                Koszt promocji w całości ponosi Appet - przychód Sprzedawcy nie ulega zmianie. Różnica kwoty sprzedaży wynikająca z powyższego rabatu zostanie wyrównana przez Appet.
                            </div>
                        </div> : null
                    }

                    {
                        data.discount && data.deliverView.giftDelivery ? <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>Aktywowane rabaty:</div>
                            <div style={{fontWeight: 700}}>{data.discount.code}</div> ({data.discount.discountValueFormatted})
                            <div style={{color: 'grey'}}>
                                Koszt promocji w całości ponosi Appet - przychód Sprzedawcy nie ulega zmianie. Różnica kwoty sprzedaży wynikająca z powyższego rabatu zostanie wyrównana przez Appet.
                            </div>
                        </div> : null
                    }
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "40%",
                        flexDirection: "column",
                    }}
                >
                    {
                        data.discount && orderItems[0].discount ? <ContentPriceDetail>
                            <div>Wartość produktów przed rabatem:</div>
                            <div>{data.originalTotalItemPriceGross} zł</div>
                        </ContentPriceDetail> : null
                    }
                    <ContentPriceDetail>
                        {
                            data.discount && data.discount.type !== 'FREE_SHIPPING' ?
                                <>
                                    <div>Rabaty pokrywane przez {!data.discount.isProductOwnerCostSide ? 'Appet' : 'Sprzedawce'}:</div>
                                    <div> -{Math.round((data.originalTotalItemPriceGross  - data.totalItemPriceGross) * 100) / 100} zł</div>
                                </>
                                : null
                        }
                    </ContentPriceDetail>
                    <ContentPriceDetail>
                        {
                            data.deliverView.giftDelivery ?
                                <>
                                    <div>Rabat pokryty przez Appet jako darmowa zostawa: </div>
                                    <div>-{data.deliverView.deliveryProductOwner.price}zł</div>
                                </>
                                : null
                        }
                    </ContentPriceDetail>
                    <ContentPriceDetail>
                        <div>Wartość produktów {data.discount ? 'po rabacie:' : null}</div>
                        <div>{data.totalItemPriceGross} zł</div>
                    </ContentPriceDetail>
                    <ContentPriceDetail>
                        <div>Wysyłka:</div>
                        {isDiscount && isDiscount.type === 'FREE_DELIVERY' ?<div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <div style={{ textDecoration: 'line-through'}}>{data.deliverView.price} zł</div>
                            <div>0 zł</div>
                        </div> : <div>{data.deliverView.price} zł</div>}
                    </ContentPriceDetail>
                    <SummaryOrderDetail>
                        <div>Suma za zamówienie:</div>
                        <div>
                            {data.totalPriceGross} zł
                            {/*{(data.totalItemPriceGross + data.deliverView.price - 12.34)*/}
                            {/*    .toString()*/}
                            {/*    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}*/}
                            {/*zł*/}
                        </div>
                    </SummaryOrderDetail>
                </div>
            </div>
        </>
    );
};

export default OrderItemsTable;
