import {useContext} from "react";
import {Input} from "antd";

const OrderNumberSearch = ({context, type}) => {

    const {dispatch, state} = useContext(context);

    return <Input defaultValue={state.orderNumber}
                  onBlur={(e) => dispatch({type: type, payload: e.target.value})}/>
}

export default OrderNumberSearch;