import React from "react";

const PaymentStatusLabel = ({paid, textTrue = "Opłacone", textFalse = 'Nieopłacone'}) => {

    return paid ?  <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
        <div style={{height: '5px', width: '5px', backgroundColor: 'green', borderRadius: '50%'}}></div>
        {textTrue}</div> : <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
        <div style={{height: '5px', width: '5px', backgroundColor: 'red', borderRadius: '50%'}}></div>
        {textFalse}</div>;
}

export default PaymentStatusLabel;