import {Button} from "antd";
import React, {useState} from "react";
import UserForm from "../../Forms/Users/UserForm";
import useCreateNewUser from "../../../hooks/users/useCreateNewUser";

const NewUserModal = ({ reload }) => {
  const [open, setIsOpen] = useState(false);
  const { create } = useCreateNewUser();

  const handleOk = (values) => {

    create(values).finally(() => {
      reload();
      setIsOpen(false);
    });
  };

  return (
    <>
      <Button
        style={{ marginBottom: "24px" }}
        type="primary"
        onClick={() => setIsOpen(!open)}
      >
        Dodaj nowego użytkownika
      </Button>
      <UserForm
        title={"Dodaj nowe konto użytkownika"}
        handleOk={handleOk}
        onCancel={() => setIsOpen(false)}
        open={open}
      />
    </>
  );
};

export default NewUserModal;
