import styled from "styled-components";
import { themes } from "../../../layout/styles/ColorStyles";

const ButtonWrapper = styled.button`
  height: 35px;
  width: ${(props) => (props.width ? props.width : "100px")};
  background-color: ${themes.loginButtonColor};
  border: none;
  color: white;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease-in;

  :hover {
    background-color: ${themes.logginButtonColorHover};
  }
`;

const CustomSubmitButton = ({ title, width }) => {
  return (
    <ButtonWrapper width={width} type="submit">
      {title}
    </ButtonWrapper>
  );
};

export default CustomSubmitButton;
