import { Image, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { themes } from "../../../layout/styles/ColorStyles";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 6px;
`;

const ProductListTooltip = ({ products, text }) => {
  const title = () => {
    return products.map((it, index) => {
      return (
        <Wrapper key={index}>
          <Image
            key={it.id}
            height={70}
            width={70}
            src={it.product.photos[0]}
            preview={false}
          />
          <div
            style={{
              color: themes.black,
              display: "flex",
              gap: "5px",
              width: "303px",
              marginLeft: "5px",
            }}
          >
            <div style={{ fontWeight: "590" }}>{it.quantity}x</div>{" "}
            {it.product.name}
          </div>
        </Wrapper>
      );
    });
  };

  return (
    <Tooltip color="white" placement="bottom" title={title}>
      {text}
    </Tooltip>
  );
};

export default ProductListTooltip;
