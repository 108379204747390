import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Wrapper from "./Wrapper";
import { AuthProvider } from "./context/AuthContext";
import "antd/dist/antd.min.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <Wrapper />
  </AuthProvider>

  // ReactDOM.render(
  //     // <React.StrictMode>
  //         <AuthProvider>
  //             <Wrapper />
  //         </AuthProvider>,
  //     // </React.StrictMode>,
  //     document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
