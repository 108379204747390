import styled from "styled-components";
import UserProfile from "../components/Menu/UserProfile";
import {DollarOutlined, PhoneOutlined} from "@ant-design/icons";
import React, {useContext, useEffect, useRef, useState} from "react";
import {TooltipContent, TooltipContentItem, TooltipWrapper,} from "./styles/TooltipStyle";
import {Badge} from "antd";
import {Link} from "react-router-dom";
import {themes} from "./styles/ColorStyles";
import CounterOrderContext from "../context/CounterOrderContext";
import useGetOrderCounter from "../hooks/orders/useGetOrderCounter";
import SearchTopMenu from "../components/Input/SearchTopMenu";
import useGetOrderByOrderNumber from "../hooks/orders/useGetOrderByOrderNumber";

const SupportWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-weight: 500;
  font-size: 17px;
  color: #363636;
  height: 100%;


  :hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

const TopMenu = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef();
    const {state} = useContext(CounterOrderContext);
    const {getCountOrder, isLoading: isLoadingCounter} = useGetOrderCounter();
    const {getOrder, isLoading} =
        useGetOrderByOrderNumber();

    const [orders, setOrders] = useState([]);


    useEffect(() => {
        getCountOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = (event) => {
        setShowTooltip(!showTooltip);
        event.preventDefault();
    };

    const handleClickOutside = (event) => {
        if (!tooltipRef.current.contains(event.target)) {
            setShowTooltip(false);
        }
    };

    const handleChange = (value) => {
        getOrder(value.target.value).then((res) => {
            setOrders(res);
        });
    }

    if(isLoadingCounter) return '';

    return (
        <Wrapper>
            <SearchTopMenu handleChange={handleChange} orders={orders} isLoading={isLoading}/>
            <div
                style={{
                    width: "35%",
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: 'stretch',
                    alignItems: "center",
                }}
            >
                <Link to={''} >
                    <div style={{
                        height: "100%",
                        display: "flex",
                        flex: '1',
                        flexDirection: "row",

                    }}>
                        <div style={{width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Badge
                                count={state.counter}
                                style={{color: 'black', backgroundColor: themes.activeMenuItemColor}}
                            >
                                <DollarOutlined
                                    style={{
                                        color: themes.activeMenuItemColor,
                                        fontSize: "40px",
                                    }}
                                />
                            </Badge>
                        </div>
                        <div style={{
                            width: '70%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: '700',
                            marginLeft: '10px',
                            color: 'black'
                        }}>
                            Zamówienia do zaakceptowania
                        </div>
                    </div>
                </Link>
                <>
                    <SupportWrapper onClick={(e) => handleClick(e)}>
                        <PhoneOutlined/>
                        Kontakt
                    </SupportWrapper>
                    <div ref={tooltipRef}>
                        <TooltipWrapper showTooltip={showTooltip}>
                            <div className="email">Kontakt</div>
                            <TooltipContent>
                                <TooltipContentItem>Email: bok@appet.pl</TooltipContentItem>
                                <TooltipContentItem>Telefon: 780 030 801</TooltipContentItem>
                            </TooltipContent>
                        </TooltipWrapper>
                    </div>
                </>
                <div style={{display: 'flex', flex: 1, marginRight: '10px'}}>
                    <UserProfile/>
                </div>
            </div>
        </Wrapper>
    );
};

export default TopMenu;
