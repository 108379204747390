import styled from "styled-components";
import {themes} from "./ColorStyles";

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  color: #364a63;
  font-weight: 500;
  border-bottom: 1px solid #e5e9f2;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const IconWrapper = styled.div`
  background-color: ${themes.loginButtonColor};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: "#ffffff";
  opacity: 0.5;
`;


export const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
    overlay: {
        backgroundColor: "rgba(169,169,169,0.8)",
    },
};
