import styled from "styled-components";
import ProductVariantsFiltersContext from "../../../context/ProductVariantsFiltersContext";
import React, {useContext} from "react";
import {Button, Form} from "antd";
import {
    CHANGE_PAGE,
    CLEAR_ALL,
    UPDATE_BRAND_IDS,
    UPDATE_IS_ACTIVE,
    UPDATE_IS_DESCRIPTION_LOCKED,
    UPDATE_IS_PHOTO_LOCKED,
    UPDATE_IS_PRICE_LOCKED,
    UPDATE_SEARCH_QUERY,
} from "../../../reducers/ProductVariantsFiltersReducer";
import TextSearchInput from "./TextSearchInput";
import PriceInput from "./PriceInput";
import BrandSelect from "./BrandSelect";
import IsBlockedSelect from "./IsBlockedSelect";

const Content = styled(Form)`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 5px;
`;

const FilterProductVariant = ({brands, reload}) => {
    const {dispatch, state} = useContext(ProductVariantsFiltersContext);
    const [form] = Form.useForm();

    const onFinish = () => {
        dispatch({type: CHANGE_PAGE, payload: 1});
        reload({
            ...state,
            page: 1
        });
    };

    const handleOnReset = () => {
        form.resetFields();
        dispatch({type: CLEAR_ALL});
    };

    return (
        <Content form={form} onFinish={onFinish} labelCol={{span: 24}}>
            <Form.Item label="Nazwa produktu, ID Produktu lub Ean" name="search">
                <TextSearchInput type={UPDATE_SEARCH_QUERY} context={ProductVariantsFiltersContext}/>
            </Form.Item>
            <Form.Item label="Cena brutto produktu" name='prices'>
                <PriceInput/>
            </Form.Item>
            <Form.Item name="brandIds" label="Producent">
                <BrandSelect brands={brands} type={UPDATE_BRAND_IDS} context={ProductVariantsFiltersContext}/>
            </Form.Item>
            <Form.Item
                name="isActive"
                label="Czy produkt aktywny"

            >
                <IsBlockedSelect type={UPDATE_IS_ACTIVE} test={state.isActive}/>
            </Form.Item>
            <Form.Item
                name="isPhotoLocked"
                label="Czy produkt zawiera blokadę zdjęć"
            >
                <IsBlockedSelect type={UPDATE_IS_PHOTO_LOCKED} test={state.isPhotoLocked}/>
            </Form.Item>
            <Form.Item
                name="isDescriptionLocked"
                label="Czy produkt zawiera blokadę opisów"
            >
                <IsBlockedSelect type={UPDATE_IS_DESCRIPTION_LOCKED} test={state.isDescriptionLocked}/>
            </Form.Item>
            <Form.Item
                name="isPriceLocked"
                label="Czy produkt zawiera blokadę cen">
                <IsBlockedSelect type={UPDATE_IS_PRICE_LOCKED} test={state.isPriceLocked}/>
            </Form.Item>
            <Form.Item></Form.Item>
            <Form.Item>
                <Button
                    style={{marginRight: "10px"}}
                    type="primary"
                    htmlType="submit"
                >
                    Zastosuj
                </Button>
                <Button htmlType="button" onClick={() => handleOnReset()}>
                    Wyczyść filtry
                </Button>
            </Form.Item>
        </Content>
    );
};

export default FilterProductVariant;
