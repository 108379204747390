import {useState} from "react";
import axiosInstance from "../../lib/axios";

interface UpdateDeliveryPOIsFreeDelivery {
    deliveryProductOwnerId: string,
    isFreeFromPrice: boolean
}

const useUpdateDeliveryProductOwnerIsFreeDelivery = () => {

    const [isLoading, setisLoading] = useState<boolean>(false);

    const update = async (obj: UpdateDeliveryPOIsFreeDelivery): Promise<any> => {
        try {
            setisLoading(true);
            await axiosInstance.put("/partner/delivery/is_free_delivery/" + obj.deliveryProductOwnerId, obj);

        } catch (err) {
            setisLoading(false);
            throw err;
        }
    };

    return { update, isLoading };
}

export default useUpdateDeliveryProductOwnerIsFreeDelivery;