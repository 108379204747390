import {Form, Input} from "antd";
import React, {useContext} from "react";
import OrderFiltersContext from "../../../../context/OrderFiltersContext";
import {UPDATE_PRICE_FROM, UPDATE_PRICE_TO} from "../../../../reducers/OrderFiltersReducers";

const PriceInput = () => {

    const {dispatch, state} = useContext(OrderFiltersContext);

    return <div
        style={{
            display: "flex",
            flexDirection: "row",
        }}
    >
        <Form.Item name="priceFrom" noStyle initialValue={state.priceFrom}>
            <Input style={{ width: "100%" }} type='number' onBlur={(e) => dispatch({type: UPDATE_PRICE_FROM, payload: e.target.value})}/>
        </Form.Item>
        <Form.Item noStyle>
            <Input
                style={{
                    width: "15%",
                    borderLeft: 0,
                    borderRight: 0,
                    pointerEvents: "none",
                }}
                placeholder="~"
                disabled
            />
        </Form.Item>
        <Form.Item
            initialValue={state.priceTo}
            name="priceTo"
            noStyle
            rules={[
                ({ getFieldValue }) => ({
                    validator() {
                        if (
                            !getFieldValue("priceFrom") ||
                            !getFieldValue("priceTo") ||
                            getFieldValue("priceTo") >= getFieldValue("priceFrom")
                        ) {
                            return Promise.resolve();
                        }
                        return Promise.reject("Cena do musi być większa od ceny od!");
                    },
                }),
            ]}
        >
            <Input type='number' style={{ width: "100%" }} onBlur={(e) => dispatch({type: UPDATE_PRICE_TO, payload: e.target.value})}/>
        </Form.Item>
    </div>
}

export default PriceInput;
