import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useGetOrderById = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);
  const getOrder = async (id: string): Promise<any> => {
    try {
      const res = await axiosInstance.get(`/partner/order/show/` + id);

      if (res.status === 200) {
        setData(res.data ? res.data : []);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return { isLoading, data, getOrder };
};

export default useGetOrderById;
