import NewDeliveryAssigmentForProductOwnerForm from "../../Forms/DeliveryToProductOwner/NewDeliveryAssigmentForProductOwnerForm";
import React, { useState } from "react";
import useUpdateDeliveryToProductOwnerInfo from "../../../hooks/deliveries/useUpdateDeliveryToProductOwnerInfo";
import moment from "moment";
import styled from "styled-components";
import { themes } from "../../../layout/styles/ColorStyles";

const Button = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: ${themes.editButton};

  cursor: pointer;
`;
const UpdateDeliveryToProductOwnerInfoModal = ({ reload, row }) => {
  const [open, setIsOpen] = useState(false);
  const { update } = useUpdateDeliveryToProductOwnerInfo();
  const handleOk = (values) => {
    update({
      id: row.id,
      freeFromPrice: values.freeFromPrice,
      price: values.price,
      isFreeFromPrice: values.isFreeFromPrice,
      isActive: values.isActive,
      deliveryDurationTime: values.deliveryDurationTime,
      maxTimeSendSameDay: values.isDeliverySameDay
        ? moment(values.maxTimeSendSameDay).format("HH:ss")
        : null,
      isDeliveryNextDay: values.isDeliverySameDay,
    }).finally(() => {
      reload();
      setIsOpen(false);
    });
  };
  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Edytuj</Button>
      <NewDeliveryAssigmentForProductOwnerForm
        title={"Edytuj metodę dostawy"}
        handleOk={handleOk}
        onCancel={() => setIsOpen(false)}
        open={open}
        row={row}
      />
    </>
  );
};

export default UpdateDeliveryToProductOwnerInfoModal;
