import React, {useContext, useEffect} from "react";
import {Breadcrumb} from "antd";
import useGetOrders from "../../hooks/orders/useGetOrders";
import OrderFiltersContext from "../../context/OrderFiltersContext";
import OrderTable from "../../components/Tables/Orders/OrderTable";
import FormIndicator from "../../components/Forms/FormIndicator";
import PaginationList from "../../components/PaginationList";
import useGetAllDeliveries from "../../hooks/deliveries/useGetAllDeliveries";
import {CHANGE_PAGE_AND_LIMIT} from "../../reducers/OrderFiltersReducers";
import FilterCollapse from "../../components/Collaps/FilterCollapse";
import FilterOrderList from "../../components/Forms/Orders/Filters/FilterOrderList";

const Orders = () => {
    const {data, isLoading, reload} = useGetOrders();
    const {state} = useContext(OrderFiltersContext);
    const {data: deliveries, isLoading: isLoadingDeliveries} =
        useGetAllDeliveries();

    useEffect(() => {
        reload(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.limit]);
    return (
        <>
            <Breadcrumb style={{paddingBottom: "20px"}}>
                <Breadcrumb.Item>Panel Sprzedawcy</Breadcrumb.Item>
                <Breadcrumb.Item>Zamówienia</Breadcrumb.Item>
                <Breadcrumb.Item>Lista zamówień</Breadcrumb.Item>
            </Breadcrumb>
            {!isLoadingDeliveries ? (<FilterCollapse children={<FilterOrderList deliveries={deliveries} reload={reload}/>}/>) : (
                <FormIndicator numberOfElements={1}/>
            )}
            {!isLoading ? (
                <>
                    <PaginationList
                        pagination={data.pagination}
                        FiltersContext={OrderFiltersContext}
                        type={CHANGE_PAGE_AND_LIMIT}
                    />
                    <OrderTable data={data.results} reload={() => reload(state)}/>
                    <PaginationList
                        pagination={data.pagination}
                        FiltersContext={OrderFiltersContext}
                        type={CHANGE_PAGE_AND_LIMIT}
                    />
                </>
            ) : (
                <div style={{marginTop: "10px"}}>
                    <FormIndicator numberOfElements={3}/>
                </div>
            )}
        </>
    );
};
export default Orders;
