import React from "react";
import {ProductVariantFiltersProvider} from "./ProductVariantsFiltersContext";
import {OrderFiltersProvider} from "./OrderFiltersContext";
import {ProductVariantProvider} from "./ProductVariantContext";
import {CounterOrderProvider} from "./CounterOrderContext";

interface Props {
    components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
    children: React.ReactNode;
}

// tutaj nalezy umieszczac providery, które mają zostać przekazane do aplikacji
const providers = [
    ProductVariantFiltersProvider,
    OrderFiltersProvider,
    ProductVariantProvider,
    CounterOrderProvider
];

const AppContextProvider = (props: Props) => {
    const {children} = props;
    return (
        <>
            {providers.reduceRight((acc, Comp) => {
                return <Comp>{acc}</Comp>;
            }, children)}
        </>
    );
};

export default AppContextProvider;
