import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";
import {Table} from "antd";

const OrderSettlementInvoiceBillTable  = ({data = []}) => {

    const columns = [
        {
            title: 'Data',
            dataIndex: 'orderDate',
            key: 'orderDate',
        },
        {
            title: 'Numer zamówienia',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: 'Kwota zamówienia',
            dataIndex: 'orderPrice',
            key: 'orderPrice',
        },
        {
            title: 'Prowizja Appet',
            dataIndex: 'orderCommission',
            key: 'orderCommission',
        },
        {
            title: 'Rozliczono',
            dataIndex: 'billPaid',
            key: 'orderSettlement',
            render: (row) => <PaymentStatusLabel paid={row} textFalse={'Nie'} textTrue={'Tak'}/>
        }
    ];

    return <Table pagination={false} dataSource={data ? data.map((it, index) => {
        return {...it, key: index}
    }) : []} columns={columns} />
}


export default OrderSettlementInvoiceBillTable;