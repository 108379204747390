import {themes} from "../layout/styles/ColorStyles";

const CREATED = "created";
const SUBMITTED = "submitted";
const PACKAGED = "packaged";
const SEND = "send";
const COMPLETED = "completed";
const RETURNED_ACCEPTED = "returned_accepted";
const RETURNED_REJECTED = "returned_rejected";
const RETURNED_REFUNDED = "returned_refunded";
const CANCELED_BY_SELLER = "cancelled_by_seller";
const CANCELED_BY_CUSTOMER = "cancelled_by_customer";
const CANCELLED_NOT_PAID    = 'cancelled_not_paid';
const PACKAGED_LOST         = 'packaged_lost';
const COMPLAINT             = 'complaint';

const status = [
  {
    value: CREATED,
    label: "Nowe zamówienie",
    color: themes.status.created,

  },
  {
    value: SUBMITTED,
    label: "Przyjęte do realizacji",
    color: themes.status.submitted,
  },
  {
    value: PACKAGED,
    label: "Spakowane",
    color: themes.status.packaged,
  },
  {
    value: SEND,
    label: "Wysłane",
    color: themes.status.send,
  },
  {
    value: COMPLETED,
    label: "Gotowe",
    color: themes.status.completed,
  },
  {
    value: RETURNED_ACCEPTED,
    label: "Zwrot - przyjęty",
    color: themes.status.returned,
  },
  {
    value: RETURNED_REJECTED,
    label: "Zwrot - odrzucony",
    color: themes.status.returned,
  },
  {
    value: RETURNED_REFUNDED,
    label: "Zwrot - rozpatrzony",
    color: themes.status.returned,
  },
  {
    value: CANCELED_BY_CUSTOMER,
    label: "Anulowanie przez kupującego",
    color: themes.status.canceled,
  },
  {
    value: CANCELED_BY_SELLER,
    label: "Anulowanie przez sprzedającego",
    color: themes.status.canceled,
  },
  {
    value: CANCELLED_NOT_PAID,
    label: 'Anulowane brak płatności',
    color: themes.status.cancelled_not_paid
  },
  {
    value: PACKAGED_LOST,
    label: 'Paczka zgubiona',
    color: themes.status.packaged_lost
  },
  {
    value: COMPLAINT,
    label: "Zgłoszenie uszkodzenia",
    color: themes.status.complaint
  }
];

export default status;
