import Lottie from "react-lottie";
import aniomationData from "../files/indicator.json";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Indicator = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: aniomationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Wrapper>
      <Lottie options={defaultOptions} height={"30%"} width={"30%"} />
    </Wrapper>
  );
};

export default Indicator;
