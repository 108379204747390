import useGetProductVariantById from "../../hooks/productVariants/useGetProductVariantById";
import {useParams} from "react-router-dom";
import {useContext, useEffect} from "react";
import FormIndicator from "../../components/Forms/FormIndicator";
import {Breadcrumb, Card, Typography} from "antd";
import styled from "styled-components";
import ProductVariantTabs from "../../components/Tabs/ProductVariant/ProductVariantTabs";
import ProductVariantContext from "../../context/ProductVariantContext";
import productOwnerStorage from "../../lib/productOwnerStorage";

const ProductInfo = styled.div`
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
  margin-left: 5px;
`;

const ProductDetailComponent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const BoldText = styled(Typography.Text)`
  font-weight: bold;
`;

const InfoComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
`;

const { Text } = Typography;

const ProductVariantDetails = () => {
  const params = useParams();
  const { data, isLoading, getProductVariant } = useGetProductVariantById();
  const { state } = useContext(ProductVariantContext);
  const { getProductOwnerName } = productOwnerStorage;

  useEffect(() => {
    getProductVariant(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <FormIndicator numberOfElements={3} />
      ) : (
        <>
          <Breadcrumb style={{ paddingBottom: "24px" }}>
            <Breadcrumb.Item>Panel Sprzedawcy</Breadcrumb.Item>
            <Breadcrumb.Item>Lista produktów</Breadcrumb.Item>
            <Breadcrumb.Item>Szczegóły Produktu </Breadcrumb.Item>
            <Breadcrumb.Item>{data.name} </Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ display: "flex" }}>
            Szczegóły Oferty: <ProductInfo> {data.name} </ProductInfo>
          </div>
          <ProductDetailComponent>
            <ProductVariantTabs
              data={data}
              reload={() => getProductVariant(params.id)}
            />
            <div style={{ flex: 1 }}>
              <Card
                type="inner"
                title="Podsumowanie"
                extra={
                  <a
                    href={
                      process.env.REACT_APP_MAIN+"/product/"+ getProductOwnerName() + "/"+ data.slug
                    }
                  >
                    Podgląd oferty
                  </a>
                }
              >
                <InfoComponent>
                  <Text>Id oferty</Text>
                  <BoldText>{data.ownerId}</BoldText>
                </InfoComponent>
                <InfoComponent>
                  <Text>Stan</Text>
                  <BoldText>
                    {state.isActive ? "Aktywny" : "Niekatywny"}
                  </BoldText>
                </InfoComponent>
                <InfoComponent>
                  <Text>Cena brutto</Text>
                  <div>
                    <BoldText>
                      {state.price ? state.price : 'Brak daty'} zł{" "}
                    </BoldText>
                    (w tym {data.price[0].vatRate.value} % VAT)
                  </div>
                </InfoComponent>
                <InfoComponent>
                  <Text>Stan magazynowy</Text>
                  <div>
                    <BoldText>
                      {state.stock ? state.stock : 'Brak stanu'}
                    </BoldText>
                  </div>
                  {/*<div>*/}
                  {/*  <BoldText>{data.stock} szt </BoldText>*/}
                  {/*  (update: {data.updatedAt})*/}
                  {/*</div>*/}
                </InfoComponent>
              </Card>
            </div>
          </ProductDetailComponent>
        </>
      )}
    </>
  );
};

export default ProductVariantDetails;
