import styled from "styled-components";
import {Form, Formik} from "formik";
import CustomField from "../components/Forms/CutomField";
import CustomSubmitButton from "../components/Forms/Buttons/CustomSubmitButton";
import {themes} from "../layout/styles/ColorStyles";
import useAuth from "../hooks/auth/useAuth";
import Indicator from "../components/Indicator";
import {useEffect} from "react";

const Wrapper = styled.div`
  background-color: #f5f6fa;
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const LogoWrapper = styled.div`
    background-color: #f5f6fa;
`;

const FormWrapper = styled.div`
  width: 400px;
  height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormContent = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const FotterWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  color: ${themes.fotterColor};
  font-size: 14px;
`;

const LoginPage = () => {
  const { login, isLoading } = useAuth();


  useEffect(() => {
      // window.history.replaceState(null, "New Page Title", "/")
  }, []);

  if (isLoading) return <Indicator />;
  const handleSubmit = (credentials) => {
    // login(credentials).catch((err) => errorMessage("Błędne dane logowania"));
    login(credentials);
  };

  return (
    <Wrapper>
      <LogoWrapper><img src={'https://appet-prod-upload.s3.eu-central-1.amazonaws.com/static/logo-admin.png'} alt='appet-logo'/></LogoWrapper>
      <FormWrapper>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={handleSubmit}
        >
          <FormContent>
            <CustomField name={"email"} placeholder={"Email*"} labelText={""} />
            <CustomField
              name={"password"}
              placeholder={"Hasło*"}
              labelText={""}
              type={"password"}
            />
            <CustomSubmitButton title="Zaloguj" width="100%" />
          </FormContent>
        </Formik>
      </FormWrapper>

      <FotterWrapper>Designed by AKM-Web</FotterWrapper>
    </Wrapper>
  );
};

export default LoginPage;
