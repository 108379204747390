import {Image} from "antd";

const LogoPayment = ({src}) => {

    return <Image
        src={src}
        alt={src}
        style={{ width: '36px', height: '19px', objectFit: 'contain'}}
        preview={false}
    />
}

export default LogoPayment;