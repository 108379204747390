import {useContext, useState} from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";
import jwtDecode from "jwt-decode";
import authStorage from "../../lib/authStorage";
import AuthContext from "../../context/AuthContext";
import productOwnerStorage from "../../lib/productOwnerStorage";

type AuthCredentials = {
  email: string;
  password: string;
};

const useAuth = () => {
  const { setUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { errorMessage } = useAlert();

  const login = async (credentials: AuthCredentials): Promise<void> => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.post("/auth/hello", credentials);
      if (data.token) {
        authStorage.storeToken(data.token);
        const user = jwtDecode(data.token);

        // @ts-ignore
        if (user.roles.indexOf("ROLE_PARTNER") === -1) {
          throw new Error("Access Denied");
        }

        setIsLoading(false);
        setUser(user);
      }
    } catch (err) {
      // throw err;
      errorMessage("Błędne dane logowania. Spróbuj ponownie");
      setIsLoading(false);
      authStorage.clearToken();
      productOwnerStorage.clearProductOwnerName();
    }
  };

  const logout = (): void => {
    authStorage.clearToken();
    setUser(null);
    productOwnerStorage.clearProductOwnerName();
  };

  const refresh = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.post("/auth/refresh");

      if (data.token) {
        authStorage.storeToken(data.token);
        const user = jwtDecode(data.token);
        setUser(user);
        setIsLoading(false);
        return;
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return { login, logout, refresh, isLoading };
};

export default useAuth;
