import { useState } from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateActivityForProductVariants {
  productVariantIds: [];
  active: boolean;
}

const useUpdateActivityForProductVariants = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { successMessage, errorMessage } = useAlert();

  const update = async (
    obj: UpdateActivityForProductVariants
  ): Promise<any> => {
    try {
      setisLoading(true);
      await axiosInstance.put("/partner/product/variant/activity/", obj);
      successMessage("Produkty zostały zaktualizowane");
    } catch (err) {
      setisLoading(false);
      errorMessage("Błąd zapisu. Spróbuj ponownie.");
      throw err;
    }
  };

  return { update, isLoading };
};

export default useUpdateActivityForProductVariants;
