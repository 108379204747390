import React, {useContext} from "react";
import ProductVariantsFiltersContext from "../../../context/ProductVariantsFiltersContext";
import {Input} from "antd";
import {UPDATE_PRICE_FROM, UPDATE_PRICE_TO} from "../../../reducers/ProductVariantsFiltersReducer";

const PriceInput = () => {

    const {dispatch, state} = useContext(ProductVariantsFiltersContext);

    const [priceFrom, setPriceFrom] = React.useState(state.priceFrom);
    const [priceTo, setPriceTo] = React.useState(state.priceTo);

    return <div
        style={{
            display: "flex",
            flexDirection: "row",
        }}
    >
        <Input min={0} defaultValue={priceFrom} style={{width: "100%"}} onBlur={(e) => dispatch({
            type: UPDATE_PRICE_FROM,
            payload: e.target.value
        }) && setPriceFrom(e.target.value)}/>
        <Input
            style={{
                width: "15%",
                borderLeft: 0,
                borderRight: 0,
                pointerEvents: "none",
            }}
            placeholder="~"
            disabled
        />
        {/*<Form.Item*/}
        {/*    name="priceTo"*/}
        {/*    noStyle*/}
        {/*    initialValue={priceTo}*/}
        {/*    rules={[*/}
        {/*        ({getFieldValue}) => ({*/}
        {/*            validator() {*/}
        {/*                if (*/}
        {/*                    !getFieldValue("priceFrom") ||*/}
        {/*                    !getFieldValue("priceTo") ||*/}
        {/*                    getFieldValue("priceTo") >= getFieldValue("priceFrom")*/}
        {/*                ) {*/}
        {/*                    return Promise.resolve();*/}
        {/*                }*/}
        {/*                return Promise.reject("Cena do musi być większa od cena od!");*/}
        {/*            },*/}
        {/*        }),*/}
        {/*    ]}*/}
        {/*>*/}
        <Input type='number' min={0} defaultValue={priceTo} style={{width: "100%"}} onBlur={(e) => dispatch({
            type: UPDATE_PRICE_TO,
            payload: e.target.value
        }) && setPriceTo(e.target.value)}/>
        {/*</Form.Item>*/}
    </div>
}

export default PriceInput;