import {Button} from "antd";
import {useState} from "react";
import {useParams} from "react-router-dom";
import useUploadRegulation from "../../../hooks/productOwner/useUploadRegulation";


const UploadStatment = ({regulation}) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const {update} = useUploadRegulation();
    const params = useParams();

    const onFileChange = (event) => {
        // Update the state
        setSelectedFile(event.target.files[0]);
    };
    // On file upload (click the upload button)
    const onFileUpload = () => {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );

        // Request made to the backend api
        // Send formData object
        update(formData, params.id);
    };

    return <div style={{display: 'flex', flexDirection: 'column', width: '200px', gap: '10px'}}>{regulation ?
        <Button style={{marginRight: '5px'}} type='primary'><a href={regulation.fullUrl}  rel="noreferrer" target='_blank'>Obceny
            Regulamin</a></Button> : ''} <><input type="file" onChange={onFileChange}/>
        <Button type='primary' onClick={onFileUpload}>
            Zapisz
        </Button></>
    </div>
};

export default UploadStatment;
