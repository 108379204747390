import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateNumberEmailContactOfProductOwner {
  phone: string;
  email: string;
}

const useUpdateNumberEmailContactOfProductOwner = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { successMessage, errorMessage } = useAlert();

  const update = async (
    obj: UpdateNumberEmailContactOfProductOwner
  ): Promise<any> => {
    try {
      setisLoading(true);
      await axiosInstance.patch("/partner/product_owner/communication/", obj);
      successMessage("Dane zmienione");
    } catch (err) {
      setisLoading(false);
      errorMessage("Błąd zapisu. Dane muszą być wypełnione");
      throw err;
    }
  };

  return { update, isLoading };
};

export default useUpdateNumberEmailContactOfProductOwner;
