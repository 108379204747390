import { Button } from "antd";
import NewDeliveryAssigmentForProductOwnerForm from "../../Forms/DeliveryToProductOwner/NewDeliveryAssigmentForProductOwnerForm";
import React, { useState } from "react";
import useAssignDeliveryToProductOwner from "../../../hooks/deliveries/useAssignDeliveryToProductOwner";
import moment from "moment";

const AssignNewDeliveryToProductOwnerModal = ({ reload, deliveries }) => {
  const [open, setIsOpen] = useState(false);
  const { assign } = useAssignDeliveryToProductOwner();

  const handleOk = (values) => {
    assign({
      deliveryId: values.delivery,
      freeFromPrice: values.freeFromPrice,
      price: values.price,
      isFreeFromPrice: values.isFreeFromPrice,
      isActive: values.isActive,
      deliveryDurationTime: values.deliveryDurationTime,
      maxTimeSendSameDay: values.isDeliverySameDay
        ? moment(values.maxTimeSendSameDay).format("HH:ss")
        : null,
      isDeliveryNextDay: values.isDeliverySameDay,
    }).finally(() => {
      reload();
      setIsOpen(false);
    });
  };

  return (
    <>
      <Button
        style={{ marginBottom: "24px" }}
        type="primary"
        onClick={() => setIsOpen(!open)}
      >
        Dodaj nową metodę dostawy
      </Button>
      <NewDeliveryAssigmentForProductOwnerForm
        title={"Dodaj nową metodę płatności"}
        deliveries={deliveries}
        handleOk={handleOk}
        onCancel={() => setIsOpen(false)}
        open={open}
      />
    </>
  );
};

export default AssignNewDeliveryToProductOwnerModal;
