import {Switch, Table} from "antd";
import {themes} from "../../layout/styles/ColorStyles";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import EditUserModal from "../Modals/Users/EditUserModal";
import useUpdateUserActivity from "../../hooks/users/useUpdateUserActivity";
import ChangePasswordModal from "../Modals/Users/ChangePasswordModal";

const UserTable = ({ data, reload }) => {
  const [users, setUsers] = React.useState([]);
  const { update } = useUpdateUserActivity();

  useEffect(() => {
    let u = data.map((it) => {
      return {
        key: it.id,
        ...it,
      };
    });
    setUsers(u);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleUpdateUserActivity = (row, activity) => {
    update({ id: row.id, isActive: activity }).then(() => reload());
  };

  const columns = [
    {
      title: "Aktywność",
      dataIndex: "",
      align: "center",
      render: (row) => (
        <Switch
          style={{
            backgroundColor: row.active
              ? themes.activeMenuItemColor
              : themes.switchInActive,
          }}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={row.active}
          onChange={(checked) => handleUpdateUserActivity(row, checked)}
        />
      ),
    },
    {
      title: "Użytkownik",
      dataIndex: "",
      align: "center",
      render: (row) => (
        <div>
          <span>
            {row.name} {row.lastName}
          </span>
        </div>
      ),
    },
    {
      title: "Adres email",
      dataIndex: "",
      align: "center",
      render: (row) => (
          <div>
            <span>{row.email}</span>
          </div>
      ),
    },
    {
      title: "Numer telefonu",
      dataIndex: "",
      align: "center",
      render: (row) => (
        <div>
          <span>{row.number ? row.number : "Brak numeru"}</span>
        </div>
      ),
    },
    {
      title: "Uprawnienia",
      dataIndex: "",
      align: "center",
      render: () => (
        <div>
          <span>Partner</span>
        </div>
      ),
    },
    {
      title: "Akcja",
      dataIndex: "",
      key: "edit",
      render: (row) => <><EditUserModal user={row} reload={reload} />{" "}<ChangePasswordModal item={row}/></>,
    },
  ];
  return (
    <Table
      dataSource={users}
      columns={columns}
      rowClassName="editable-row"
      pagination={false}
    />
  );
};

export default UserTable;
