import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import {DownOutlined} from "@ant-design/icons";

const WrapperItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 25px;
  width: 100%;
  height: 45px;
  transition: 0.3s ease-in;
  > * {
    transition: 0.3s ease-in;
  }

  :hover {
    background: #3F5C74;
    cursor: pointer;
  }
`;


const ItemName = styled.div`
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16.71px;
  color: white;
`;

const ItemNameChild = styled(ItemName)`
  padding-left: 48px;
`;

const animation = keyframes`
  from {opacity: 0; transform: translateY(-10px);  }
  to {opacity: 1; transform: translateY(0px);}
`;

const ChildrenWrapper = styled.div`
  animation: ${animation} 1s;
  display: grid;
  grid-template-columns: 100%;
  background-color:#21394D;
`;

const ChildItem = styled.div`
  height: 45px;
  display: grid;
  align-items: center;
  transition: 0.3s ease-in;
  > * {
    transition: 0.3s ease-in;
  }
  background-color: ${(props) =>
    props.isActive ? '#397BB2' : "#21394D"};
  :hover {
    background: #3F5C74;
    cursor: pointer;
  }
`;

const MenuItem = ({
  item,
  isActive,
  handleClick,
  activeChildName,
  setActiveChildName,
}) => {

  const children = item.children?.map((child, index) => (
    <div key={index}>
      <NavLink to={child.path} style={{ textDecoration: "none" }}>
        <ChildItem onClick={() => setActiveChildName(child.name)} isActive={child.name === activeChildName}>
          <ItemNameChild isActive={child.name === activeChildName}>
            {child.name}
          </ItemNameChild>
        </ChildItem>
      </NavLink>
    </div>
  ));

  const checkAreChildren = (e, item) => {
    if (item.children) {
      e.preventDefault();
    }
  };

  return (
    <>
      <NavLink
        to={item.path}
        onClick={(e) => checkAreChildren(e, item)}
        style={{ textDecoration: "none" }}
      >
        <WrapperItem onClick={handleClick}>
          <ItemName isActive={isActive && !children}>{item.name}</ItemName>
          {children ? <DownOutlined style={{fontSize: '17px', fontFamily: 'SF Pro', color: 'white'}}/> : null}
        </WrapperItem>
      </NavLink>
      {isActive && item.children ? (
          <div style={{backgroundColor: '#21394D', width: '100%'}}>
        <ChildrenWrapper>{children}</ChildrenWrapper>
          </div>
      ) : null}
    </>
  );
};

export default MenuItem;
