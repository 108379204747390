import React, {useReducer} from "react";
import {initialState, OrderFilterReducers} from "../reducers/OrderFiltersReducers";

const OrderFiltersContext = React.createContext();

export const OrderFiltersProvider = ({children}) => {

    const [state, dispatch] = useReducer(OrderFilterReducers, initialState);

    return <OrderFiltersContext.Provider value={{state,dispatch}}>
        {children}
    </OrderFiltersContext.Provider>
}

export default OrderFiltersContext;