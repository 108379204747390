import React from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const MyEditor = ({ value, setValue }) => {
  const handleChange = (text) => {
    setValue(text)
  }
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={handleChange}
    />
  );
};

export default MyEditor;
