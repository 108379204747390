import axiosInstance from "../../lib/axios";
import {useState} from "react";

const useGetSettlementInvoiceBills = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getOrderSettlementBills = async (id: string): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/partner/b2b/settlement/invoice/bills/` + id);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    }

    return {isLoading, data, getOrderSettlementBills};
}

export default useGetSettlementInvoiceBills;