import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetOrderByOrderNumber = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);
    const getOrder = async (search: string): Promise<any> => {
        setIsLoading(true);
        try {
            const res = await axiosInstance.get(`/partner/order/search/`, {
                params: {search},
            });

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
                return res.data;
            }

        } catch (err) {
            setIsLoading(false);
        }
    };

    return {isLoading, data, getOrder, setIsLoading, setData};
};

export default useGetOrderByOrderNumber;
