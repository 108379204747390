import React, {useState} from "react";
import styled from "styled-components";
import routing from "../routing/routing";
import MenuItem from "../components/Menu/MenuItem";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 140px auto;
`;

const LogoWrapper = styled.div`
  padding: 30px 40px;
`;

const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftMenu = () => {
  const [activeIdx, setActiveIdx] = useState(0);
  const [activeChildName, setActiveChildName] = useState(null);

  const handleClick = (idx) => {
    setActiveIdx(idx);
  };

  const handleActiveChild = (nameSubmenu) => {
    setActiveChildName(nameSubmenu);
  };

  return (
    <Wrapper>
      <LogoWrapper>
          <img src='https://appet-prod-upload.s3.eu-central-1.amazonaws.com/static/logo_yellow_white.png' alt={"logo-appet"}/>
      </LogoWrapper>
      <MenuItemsWrapper>
        {routing.map((item, index) => (
          <MenuItem
            item={item}
            key={index}
            isActive={index === activeIdx}
            activeChildName={activeChildName}
            setActiveChildName={handleActiveChild}
            handleClick={() => handleClick(index)}
          />
        ))}
      </MenuItemsWrapper>
    </Wrapper>
  );
};

export default LeftMenu;
