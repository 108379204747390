export const themes = {
    // left menu
    activeMenuItemColor: "#FFBD3C",

    //login page
    loginButtonColor: "#FFBD3C",
    logginButtonColorHover: "#FFBD3C",
    fotterColor: "#8094ae",
    switchInActive: '#BFBFBF',
    black: 'black',
    editButton: '#1890FF',

    light: {
        backgroundColor: `#f5f6fa`,
        backgroundColorLeftMenu: `#ffffff`,
    },
    dark: {
        backgroundColor: `#1F1F47`,
        backgroundColorLeftMenu: `#000000`,
    },

    status: {
        created: '#E77E22',
        submitted: '#469DFB',
        packaged: '#6A2E85',
        send: '#3D770F',
        completed: '#1130A7',
        returned: '#F63C45',
        canceled: '#434343',
        cancelled_not_paid: '#5C0011',
        packaged_lost: '#5C0011',
        complaint: '#5C0011',
    }
};