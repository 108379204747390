import {Tooltip} from "antd";
import React from "react";
import LogoDelivery from "../../Image/LogoDelivery";

function getInfo(delivery) {
    let name = delivery.deliverView.deliveryProductOwner.deliveryView.name;
    return (
        <div style={{height: "188px", width: "160px"}}>
            <div style={{fontWeight: "700"}}>Metoda dostawy</div>
            <div>{name === "Inpost" ? "InPost paczkomaty" : "Kurier " + name}</div>
            <div style={{fontWeight: "700", marginTop: "20px"}}>Metoda dostawy</div>
            <div>
                {name === "Inpost" ? (
                    delivery.deliverView.notes
                ) : (
                    <div>
                        <div>{delivery.address.name}</div>
                        <div>
                            ul. {delivery.address.street} {delivery.address.localNumber}
                        </div>
                        <div>
                            {delivery.address.postCode} {delivery.address.city}
                        </div>
                        <div> tel: {delivery.address.mobilePhone}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

const DeliveryDetailsTooltip = ({delivery}) => {
    return (<Tooltip
            placement={"top"} title={getInfo(delivery)}>
            {delivery.deliverView.deliveryProductOwner.deliveryView.logo ? (
              <div><LogoDelivery src={delivery.deliverView.deliveryProductOwner.deliveryView.logo}/></div>
            ) : (
                delivery.deliverView.deliveryProductOwner.deliveryView.name
            )}
        </Tooltip>
    );
};
export default DeliveryDetailsTooltip;
