import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateDeliveryProductOwnerInfo {
    id: string;
    isActive: boolean;
    price: string;
    isFreeFromPrice: boolean,
    freeFromPrice: null;
    maxTimeSendSameDay: string;
    deliveryDurationTime: number;
}

const useUpdateDeliveryToProductOwnerInfo = () => {
    const [isLoading, setisLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async (obj: UpdateDeliveryProductOwnerInfo): Promise<any> => {
        try {
            setisLoading(true);
            await axiosInstance.put("/partner/delivery/info/" + obj.id , obj);
            successMessage('Dodano nową metodę dostawy');
        } catch (err) {
            setisLoading(false);
            errorMessage('Błąd zapisu, spróbuj ponownie.')
            throw err;
        }
    };

    return { update, isLoading };
}

export default useUpdateDeliveryToProductOwnerInfo;