import styled from "styled-components";
import {Breadcrumb, Typography} from "antd";
import React from "react";
import UploadStatment from "../../components/Uploader/ProductOwnerInformation/UploadStatment";
import useGetProductOwnerInfo from "../../hooks/productOwner/useGetProductOwnerInfo";
import FormIndicator from "../../components/Forms/FormIndicator";
import UpdateProductOwnerInfoContactForm
    from "../../components/Forms/PRoductOwnerInfo/UpdateProductOwnerInfoContactForm";

const Wrapper = styled.div`
  background-color: #fff;
  padding: 20px;
`;

const { Text, Title } = Typography;
const ProductOwnerInformation = () => {
  const { data, isLoading, reload } = useGetProductOwnerInfo();

  return (
    <>
      <Breadcrumb style={{ paddingBottom: "20px" }}>
        <Breadcrumb.Item>Ustawienia sprzedaży</Breadcrumb.Item>
        <Breadcrumb.Item>Ustawienia ogólne</Breadcrumb.Item>
      </Breadcrumb>
      <Wrapper>
        {isLoading ? (
          <FormIndicator numberOfElements={3} />
        ) : (
          <>
            {" "}
            <Title level={3}>Regulamin</Title>
            <UploadStatment regulation={data.regulation}/>
            <div style={{paddingTop: '10px'}}>
            <Title level={3}>Dane adresowe</Title>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: '10px' }}
            >
              <Text>Nazwa Sklepu:</Text>
              <Text strong>{data.name}</Text>
              <Text>Ulica i numer:</Text>
              <Text strong>
                {data.headquarterView
                  ? data.headquarterView.street
                  : " Brak danych"}
                {data.headquarterView
                  ? data.headquarterView.localNumber
                  : " Brak danych"}
              </Text>
              <Text>Miasto:</Text>
              <Text strong>
                {data.headquarterView
                  ? data.headquarterView.city
                  : " Brak danych"}
              </Text>
              <Text>Kod pocztowy:</Text>
              <Text strong>
                {data.headquarterView
                  ? data.headquarterView.postCode
                  : " Brak danych"}
              </Text>
              <Text>Numer konta bankowego:</Text>
              <Text strong>
                {data.communicationView
                  ? data.communicationView.accountBankNumber
                  : "Brak danych"}
              </Text>
            </div>
            <div style={{paddingTop: '10px'}}>
            <Title level={3}>Adres do wysyłki zwrotów</Title>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: '10px' }}
            >
              <Text>Nazwa Sklepu:</Text>
              <Text strong>{data.name}</Text>
              <Text>Ulica i numer:</Text>
              <Text strong>
                {data.returnAddressView
                  ? data.returnAddressView.street +
                    data.returnAddressView.localNumber
                  : " Brak danych"}
              </Text>
              <Text>Miasto:</Text>
              <Text strong>
                {data.returnAddressView
                  ? data.returnAddressView.city
                  : " Brak danych"}
              </Text>
              <Text>Kod pocztowy:</Text>
              <Text strong>
                {data.returnAddressView
                  ? data.returnAddressView.postCode
                  : " Brak danych"}
              </Text>
            </div>
            <div style={{paddingTop: '10px'}}>
            <Title level={3}>Komunikacja</Title>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                gap: "10px",
              }}
            >
              <UpdateProductOwnerInfoContactForm reload={reload} data={data.communicationView}/>
            </div>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default ProductOwnerInformation;
