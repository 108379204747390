import styled from "styled-components";
import {useState} from "react";
import {DownOutlined, FilterOutlined, UpOutlined} from "@ant-design/icons";

const CollapsButton = styled.button`
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 17px;
  font-weight: 400;
`;

const Content = styled.div`
  padding: 20px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  background-color: white;
  margin-bottom: 10px;
`;
const FilterCollapse = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <CollapsButton onClick={() => setIsOpen(!isOpen)}>
        <FilterOutlined
          style={{
            fontSize: "17px",
            marginRight: "10px",
            fontFamily: "SF Pro",
          }}
        />
        Filtry
        {!isOpen ? (
          <DownOutlined
            style={{
              fontSize: "17px",
              marginLeft: "10px",
              fontFamily: "SF Pro",
            }}
          />
        ) : (
          <UpOutlined
            style={{
              fontSize: "17px",
              marginLeft: "10px",
              fontFamily: "SF Pro",
            }}
          />
        )}
      </CollapsButton>
      <Content isOpen={isOpen}>{children}</Content>
    </>
  );
};

export default FilterCollapse;
