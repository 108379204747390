import {useContext} from "react";
import {DatePicker} from "antd";
import moment from "moment";

const DatePickerFrom = ({type, context}) => {

    const {dispatch, state} = useContext(context);
    return <DatePicker style={{width: '100%'}} placeholder={'Data'}
                       defaultValue={state.createdAtFrom ? moment(state.createdAtFrom) : null}
                       onBlur={(e) => {
                           dispatch({type: type, payload: e.target.value + ' 00:00:00'})
                       }
                       }/>
}

export default DatePickerFrom;