import jwtDecode from "jwt-decode";

const KEY = "authToken";

const storeToken = (token) => localStorage.setItem(KEY, token);

const getToken = () => localStorage.getItem(KEY);

const clearToken = () => localStorage.removeItem(KEY);

const getUser = () => {
  const token = getToken();
  return token ? jwtDecode(token) : null;
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { storeToken, getToken, clearToken, getUser };
