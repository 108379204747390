import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateUserInfo {
  id: string;
  isActive: boolean;
  name: string;
  email: string;
  number: string;
}

const useUpdateUserInfo = () => {
  const { successMessage, errorMessage } = useAlert();

  const update = async (obj: UpdateUserInfo): Promise<any> => {
    try {
      await axiosInstance.put("/partner/users/info/", obj);
      successMessage("Zmieniono dane użytkownika");
    } catch (err) {
      errorMessage("Błąd zapisu, spróbuj ponownie.");
      throw err;
    }
  };

  return { update };
};

export default useUpdateUserInfo;
