import React, {useEffect} from "react";
import useGetOrders from "../hooks/orders/useGetOrders";
import OrderTable from "../components/Tables/Orders/OrderTable";
import FormIndicator from "../components/Forms/FormIndicator";
import useGetSummaryOrders from "../hooks/dashboard/useGetSummaryOrders";
import useGetProductOwnerInfo from "../hooks/productOwner/useGetProductOwnerInfo";

const Dashboard = () => {
  const { data, isLoading, reload } = useGetOrders();
  const { reload: getSummary, isLoading: isSummaryLoading } = useGetSummaryOrders();
  const {reload: getProductOwnerInfo} = useGetProductOwnerInfo();

  useEffect(() => {
    let obj = {
      status: "created",
    };

    getProductOwnerInfo();
    getSummary();

    reload(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {(!isLoading  && !isSummaryLoading) ? (
        <>
          {/*<Content>*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      flex: 3,*/}
          {/*      backgroundColor: themes.activeMenuItemColor,*/}
          {/*      padding: "16px",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        display: "flex",*/}
          {/*        flexDirection: "column",*/}
          {/*        height: "100%",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          display: "flex",*/}
          {/*          flex: 1,*/}
          {/*          flexDirection: "row",*/}
          {/*          justifyContent: "space-between",*/}
          {/*          alignItems: "center",*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <div style={{ width: "30%" }}>*/}
          {/*          <Badge*/}
          {/*            count={state.counter}*/}
          {/*            style={{ color: "black", backgroundColor: "white" }}*/}
          {/*          >*/}
          {/*            <DollarOutlined*/}
          {/*              style={{*/}
          {/*                color: "white",*/}
          {/*                fontSize: "40px",*/}
          {/*              }}*/}
          {/*            />*/}
          {/*          </Badge>*/}
          {/*        </div>*/}
          {/*        <div*/}
          {/*          style={{*/}
          {/*            display: "flex",*/}
          {/*            flex: 1,*/}
          {/*            justifyContent: "flex-start",*/}
          {/*            fontStyle: "normal",*/}
          {/*            fontWeight: 700,*/}
          {/*            fontSize: "16px",*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Zamówienia do zatwierdzenia*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div style={{ flex: 1 }}>*/}
          {/*        Masz {state.counter === 1 ? state.counter + ' zamówienie' : state.counter + ' zamówień'} bez potwierdzenia. Zaakceptuj je lub odrzuć.{" "}*/}
          {/*      </div>*/}
          {/*      <div style={{ flex: 1 }}>*/}
          {/*        <Link to="/orders" onClick={() => dispatch({type: UPDATE_ALL, payload: {status: 'created'}})}>*/}
          {/*        <Button*/}
          {/*          style={{*/}
          {/*            width: "100%",*/}
          {/*            height: "100%",*/}
          {/*            border: "1px solid black",*/}
          {/*            borderRadius: "5px",*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Akceptuj zamówienia*/}
          {/*        </Button>*/}
          {/*        </Link>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div style={{ backgroundColor: "white", padding: "16px", flex: 3 }}>*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        display: "flex",*/}
          {/*        flexDirection: "column",*/}
          {/*        height: "100%",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          display: "flex",*/}
          {/*          flexDirection: "row",*/}
          {/*          justifyContent: "space-between",*/}
          {/*          alignItems: "flex-start",*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <div*/}
          {/*          style={{*/}
          {/*            height: "18%",*/}
          {/*            display: "flex",*/}
          {/*            justifyContent: "flex-start",*/}
          {/*            fontWeight: 500,*/}
          {/*            fontSize: "16px",*/}
          {/*            color: themes.switchInActive,*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Sprzedaż z ostatnich 14 dni:*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div style={{ height: "25%" }}>*/}
          {/*        <div*/}
          {/*          style={{*/}
          {/*            fontStyle: "normal",*/}
          {/*            fontWeight: "500",*/}
          {/*            fontSize: "30px",*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          {summary.totalPriceSummary} zł{" "}*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          display: "flex",*/}
          {/*          flexDirection: "column",*/}
          {/*          justifyContent: "space-around",*/}
          {/*          height: "40%",*/}
          {/*          borderBottom: "1px solid",*/}
          {/*          borderBottomColor: rgba(0, 0, 0, 0.03),*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <div>Zmiana wg tygodnia: 12%</div>*/}
          {/*        <div>Zmiana wg dnia: 11%</div>*/}
          {/*      </div>*/}
          {/*      <div style={{ paddingTop: "15px" }}>*/}
          {/*        Dzienna sprzedaż: {summary.totalPriceSummaryPerDay} zł*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div style={{ backgroundColor: "white", padding: "16px", flex: 3 }}>*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        display: "flex",*/}
          {/*        flexDirection: "column",*/}
          {/*        height: "100%",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          display: "flex",*/}
          {/*          flexDirection: "row",*/}
          {/*          justifyContent: "space-between",*/}
          {/*          alignItems: "flex-start",*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <div*/}
          {/*          style={{*/}
          {/*            height: "18%",*/}
          {/*            display: "flex",*/}
          {/*            justifyContent: "flex-start",*/}
          {/*            fontWeight: 500,*/}
          {/*            fontSize: "16px",*/}
          {/*            color: themes.switchInActive,*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Wyświetlenia z ostatnich 14 dni:*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div style={{ height: "25%" }}>*/}
          {/*        <div*/}
          {/*          style={{*/}
          {/*            fontStyle: "normal",*/}
          {/*            fontWeight: "500",*/}
          {/*            fontSize: "30px",*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          12 665*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          display: "flex",*/}
          {/*          flexDirection: "column",*/}
          {/*          justifyContent: "space-around",*/}
          {/*          height: "40%",*/}
          {/*          borderBottom: "1px solid",*/}
          {/*          borderBottomColor: rgba(0, 0, 0, 0.03),*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <ResponsiveContainer>*/}
          {/*          <LineChart*/}
          {/*              data={summary.saleForChart}*/}
          {/*              margin={{*/}
          {/*                top: 5,*/}
          {/*                right: 30,*/}
          {/*                left: 20,*/}
          {/*                bottom: 5*/}
          {/*              }}*/}
          {/*          >*/}
          {/*            <Line type="monotone" dataKey="total_price" stroke="#8884d8" />*/}
          {/*          </LineChart>*/}
          {/*        </ResponsiveContainer>*/}
          {/*      </div>*/}
          {/*      <div style={{ paddingTop: "15px" }}>*/}
          {/*        Dzienne wyświetlenia: 1,242*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div style={{ backgroundColor: "white", padding: "16px", flex: 3 }}>*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        display: "flex",*/}
          {/*        flexDirection: "column",*/}
          {/*        height: "100%",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          display: "flex",*/}
          {/*          flexDirection: "row",*/}
          {/*          justifyContent: "space-between",*/}
          {/*          alignItems: "flex-start",*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <div*/}
          {/*          style={{*/}
          {/*            height: "18%",*/}
          {/*            display: "flex",*/}
          {/*            justifyContent: "flex-start",*/}
          {/*            fontWeight: 500,*/}
          {/*            fontSize: "16px",*/}
          {/*            color: themes.switchInActive,*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Transakcje z ostatnich 14 dni:*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div style={{ height: "25%" }}>*/}
          {/*        <div*/}
          {/*          style={{*/}
          {/*            fontStyle: "normal",*/}
          {/*            fontWeight: "500",*/}
          {/*            fontSize: "30px",*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          {summary.totalTransactions}*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          display: "flex",*/}
          {/*          flexDirection: "column",*/}
          {/*          justifyContent: "space-around",*/}
          {/*          height: "40%",*/}
          {/*          borderBottom: "1px solid",*/}
          {/*          borderBottomColor: rgba(0, 0, 0, 0.03),*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <ResponsiveContainer width="100%" height="100%">*/}
          {/*          <BarChart width={150} height={40} data={summary.saleForChart}>*/}
          {/*            <Bar dataKey="total_price" fill="#8884d8" />*/}
          {/*          </BarChart>*/}
          {/*        </ResponsiveContainer>*/}
          {/*      </div>*/}
          {/*      <div style={{ paddingTop: "15px" }}>*/}
          {/*        Dziennie wyświetlenia: {summary.totalTransactionsPerDay}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Content>*/}
          <OrderTable data={data.results} reload={() => reload({status: 'created'})}/>
        </>
      ) : (
        <FormIndicator numberOfElements={3} />
      )}
    </>
  );
};

export default Dashboard;
