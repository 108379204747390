export const CHANGE_PAGE = "change_page";
export const CHANGE_LIMIT = "change_limit";
export const CHANGE_PAGE_AND_LIMIT = "change_page_and_limit";
export const UPDATE_ALL = "update_all_product_variants";
export const CLEAR_ALL = "clear_all";
export const UPDATE_SEARCH_QUERY = "update_search_query";
export const UPDATE_PRICE_FROM = 'update_price_from';
export const UPDATE_PRICE_TO = 'update_price_to';
export const UPDATE_IS_ACTIVE = 'update_is_active';
export const UPDATE_BRAND_IDS = 'update_brand_ids';
export const UPDATE_IS_PHOTO_LOCKED = 'update_is_photo_locked';
export const UPDATE_IS_DESCRIPTION_LOCKED = 'update_is_description_locked';
export const UPDATE_IS_PRICE_LOCKED = 'update_is_price_locked';

export const limit = "limit";
export const page = "page";
export const search = "search";
export const priceFrom = "priceFrom";
export const priceTo = "priceTo";
export const isActive = "isActive";
export const brandIds = "brandIds";
export const isPhotoLocked = "isPhotoLocked";
export const isDescriptionLocked = "isDescriptionLocked";
export const isPriceLocked = "isPriceLocked";

export const initialState = {
    [limit]: 30,
    [page]: 1,
    [search]: "",
    [brandIds]: [],
    [priceFrom]: null,
    [priceTo]: "",
    [isActive]: null,
    [isPhotoLocked]: null,
    [isDescriptionLocked]: null,
    [isPriceLocked]: null
};

type ACTIONTYPE =
    | { type: typeof CHANGE_PAGE; payload: number }
    | { type: typeof CHANGE_LIMIT; payload: number }
    | {
    type: typeof CHANGE_PAGE_AND_LIMIT;
    payload: { [page]: number; [limit]: number };
}
    | { type: typeof CLEAR_ALL; payload: null }
    | { type: typeof UPDATE_ALL; payload: any }
    | { type: typeof UPDATE_SEARCH_QUERY; payload: string }
    | { type: typeof UPDATE_PRICE_FROM; payload: string }
    | { type: typeof UPDATE_PRICE_TO; payload: string }
    | { type: typeof UPDATE_IS_ACTIVE; payload: boolean }
    | { type: typeof UPDATE_BRAND_IDS; payload: number[] }
    | { type: typeof UPDATE_IS_PHOTO_LOCKED; payload: boolean }
    | { type: typeof UPDATE_IS_DESCRIPTION_LOCKED; payload: boolean }
    | { type: typeof UPDATE_IS_PRICE_LOCKED; payload: boolean };

export const ProductVariantsFiltersReducer = (
    state: typeof initialState,
    action: ACTIONTYPE
) => {
    switch (action.type) {
        case CLEAR_ALL: {
            return {
                ...state,
                [search]: "",
                [brandIds]: [],
                [priceFrom]: null,
                [priceTo]: "",
                [isActive]: null,
                [isPhotoLocked]: null,
                [isDescriptionLocked]: null,
                [isPriceLocked]: null
            };
        }

        case CHANGE_PAGE:
            return {
                ...state,
                [page]: action.payload,
            };
        case CHANGE_LIMIT:
            return {
                ...state,
                [limit]: action.payload,
            };
        case CHANGE_PAGE_AND_LIMIT:
            return {
                ...state,
                [page]: action.payload[page],
                [limit]: action.payload[limit],
            };
        case UPDATE_ALL:
            return {
                ...state,
            }
        case UPDATE_SEARCH_QUERY:
            return {
                ...state,
                [search]: action.payload,
            }
        case UPDATE_PRICE_FROM:
            return {
                ...state,
                [priceFrom]: action.payload,
            }
        case UPDATE_PRICE_TO:
            return {
                ...state,
                [priceTo]: action.payload,
            }
        case UPDATE_IS_ACTIVE:
            return {
                ...state,
                [isActive]: action.payload,
            }
        case UPDATE_BRAND_IDS:
            return {
                ...state,
                [brandIds]: action.payload,
            }
        case UPDATE_IS_PHOTO_LOCKED:
            return {
                ...state,
                [isPhotoLocked]: action.payload,
            }
        case UPDATE_IS_DESCRIPTION_LOCKED:
            return {
                ...state,
                [isDescriptionLocked]: action.payload,
            }
        case UPDATE_IS_PRICE_LOCKED:
            return {
                ...state,
                [isPriceLocked]: action.payload,
            }
        default:
            return state;
    }
};
