export const counter = 'counter';

export const UPDATE_COUNTER = 'update_counter';

export const initialState = {
    [counter]: 0
};

type ACTIONTYPE =| {type: typeof UPDATE_COUNTER, payload: number};

export const CounterOrderReducer = (
    state = initialState,
    action: ACTIONTYPE
) => {
    switch (action.type){
        case UPDATE_COUNTER:
            return {
                ...state,
                [counter]: action.payload
            };
        default:
            return state;
    }
}