import useUpdateNumberEmailContactOfProductOwner
    from "../../../hooks/productOwner/useUpdateNumberEmailContactOfProductOwner";
import {Button, Form, Input} from "antd";

const UpdateProductOwnerInfoContactForm = ({reload, data}) => {

    const [form] = Form.useForm();
    const {update} = useUpdateNumberEmailContactOfProductOwner();

    const onFinish = (values) => {
        // console.log(values);
        update(values).finally(() => reload());
    };


    return (
        <Form
            name="basic"
            form={form}
            layout={'vertical'}
            onFinish={onFinish}
            initialValues={{
                email: data ? data.email : '',
                phone: data ? data.number : ''
            }}
        >
            <Form.Item label="Numer telefonu" name='phone' rules={[
                {required: true, message: "Wpisz numer telefonu, pole jest wymagane!"},
            ]}>
                <Input placeholder="Numer Telefonu"/>
            </Form.Item>
            <Form.Item label="Email" name='email' rules={[
                {required: true, message: "Wpisz email, pole jest wymagane!"},
                {
                    type: "email",
                    message: "Błąd walidacji, popraw wartość!",
                },
            ]}>
                <Input placeholder="Email"/>
            </Form.Item>
            <Button type="primary" htmlType="submit">
                Zapisz
            </Button>
        </Form>
    );

}

export default UpdateProductOwnerInfoContactForm;