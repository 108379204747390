import styled from "styled-components";
import {themes} from "./ColorStyles";

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;
  width: 200px;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  border-radius: 10px;
  z-index: 1;
  visibility: ${(props) => (props.showTooltip ? "visible" : "hidden")};

  .email {
    background-color: #f5f5f5;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #e5e9f2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TooltipContent = styled.div`
  padding: 15px;
`;

export const TooltipContentItem = styled.div`
  color: #4d5c78;
  .actions {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .icon {
    font-size: 20px;
  }

  .title {
    font-size: 15px;
  }

  :hover {
    color: ${themes.activeMenuItemColor};
    cursor: pointer;
  }
`;