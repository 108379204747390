import {Switch, Table, Tooltip} from "antd";
import React from "react";
import {CheckOutlined, CloseOutlined, QuestionCircleOutlined,} from "@ant-design/icons";
import useUpdateDeliveryProductOwnerActivity from "../../hooks/deliveries/useUpdateDeliveryProductOwnerActivity";
import useUpdateDeliveryProductOwnerIsFreeDelivery
  from "../../hooks/deliveries/useUpdateDeliveryProductOwnerIsFreeDelivery";
import {themes} from "../../layout/styles/ColorStyles";
import UpdateDeliveryToProductOwnerInfoModal
  from "../Modals/DeliveryToProductOwner/UpdateDeliveryToProductOwnerInfoModal";
import LogoDelivery from "../Image/LogoDelivery";

const DeliveriesTable = ({ data, reload }) => {
  const { update: updateActivityDeliveryPO } =
    useUpdateDeliveryProductOwnerActivity();
  const { update: updateIsFreeDelivery } =
    useUpdateDeliveryProductOwnerIsFreeDelivery();

  const handleUpdateActivityDelivery = (row, isActive) => {
    updateActivityDeliveryPO({
      isActive: isActive,
      deliveryProductOwnerId: row.id,
    }).then(() => reload());
  };

  const handleUpdateIsFreeDelivery = (row, isActive) => {
    updateIsFreeDelivery({
      isFreeFromPrice: isActive,
      deliveryProductOwnerId: row.id,
    }).then(() => reload());
  };

  function renderTime(deliveryDurationTime) {
    switch (deliveryDurationTime) {
      case 24:
        return <>Dzień następny</>;
      case 48:
        return <>2 dni robocze</>;
      case 72:
        return <>3 dni robocze</>;
      case 98:
        return <>4 dni robocze</>;
      default:
        return <>Więcej niż 4 dni</>;
    }
  }

  const columns = [
    {
      title: "Nazwa dostawcy",
      dataIndex: "deliveryView",
      width: "30%",
      render: (row) => (
        <>
          <LogoDelivery src={row.logo}/>{" "}
          {row.name}{" "}
        </>
      ),
    },
    {
      title: "Aktywność",
      dataIndex: "",
      width: "10%",
      align: "center",
      render: (row) => (
        <Switch
          style={{
            backgroundColor: row.active
              ? themes.activeMenuItemColor
              : themes.switchInActive,
          }}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={row.active}
          onChange={(checked) => handleUpdateActivityDelivery(row, checked)}
        />
      ),
    },
    {
      title: "Koszt dostawy",
      dataIndex: "",
      width: "10%",
      align: "center",
      render: (row) => (
        <div
          style={{ color: row.active ? themes.black : themes.switchInActive }}
        >
          {row.price} zł
        </div>
      ),
    },
    {
      title: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1px",
          }}
        >
          {" "}
          Darmowa dostawa
          <Tooltip
            placement="top"
            title={
              "Możliwość wybrania darmowej dostawy przez klienta, po przekroczeniu progu kwotowego zamówienia."
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: "",
      width: "15%",
      align: "center",
      render: (row) => (
        <Switch
          style={{
            backgroundColor: row.deliveryFreeFromPrice
              ? themes.activeMenuItemColor
              : themes.switchInActive,
          }}
          disabled={!row.active}
          onChange={(checked) => handleUpdateIsFreeDelivery(row, checked)}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={row.deliveryFreeFromPrice}
        />
      ),
    },
    {
      title: "Próg darmowej dostawy",
      dataIndex: "",
      width: "15%",
      align: "center",
      render: (row) => (
        <div
          style={{
            color: row.isFreeFromPrice ? themes.black : themes.switchInActive,
          }}
        >
          {row.deliveryFreeFromPrice ? <>{row.freeFromPrice}zł</> : "---"}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1px",
          }}
        >
          {" "}
          Czas nadania zamówienia
          <Tooltip
            placement="top"
            title={
              "Określa czas wysyłki od momentu złożenia zamówienia przez Klienta."
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: "",
      width: "30%",
      align: "center",
      render: (row) => (
        <div
          style={{ color: row.active ? themes.black : themes.switchInActive }}
        >
          {row.deliverySameDay ? (
            <>
              Tego samego dnia <br />
              (do {row.maxTimeSendSameDay})
            </>
          ) : (
            <>{renderTime(row.deliveryDurationTime)}</>
          )}
        </div>
      ),
    },
    {
      title: "Akcja",
      dataIndex: "",
      key: "edit",
      render: (row) => (
        <UpdateDeliveryToProductOwnerInfoModal reload={reload} row={row} />
      ),
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowClassName="editable-row"
      pagination={false}
    />
  );
};

export default DeliveriesTable;
