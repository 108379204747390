import { useState } from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateOrderTrackingNumber {
  orderId: string;
  trackingNumber: string;
}

const useOrderDeliveryUpdateTrackingNumber = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { successMessage, errorMessage } = useAlert();

  const update = async (obj: UpdateOrderTrackingNumber): Promise<any> => {
    try {
      setisLoading(true);
      await axiosInstance.put(
        "/partner/order/" + obj.orderId + "/tracking-number",
        obj
      );
      successMessage("Numer śledzenia przesyłki został dodany");
    } catch (err) {
      setisLoading(false);
      errorMessage("Błąd zapisu. Spróbuj ponownie.");
      throw err;
    }
  };

  return { update, isLoading };
};

export default useOrderDeliveryUpdateTrackingNumber;
