import React, {useContext} from 'react';
import {Input} from "antd";

const TextSearchInput = ({context, type}) => {

    const {dispatch, state} = useContext(context);
    const [text, setText] = React.useState(state.search);

    return <Input defaultValue={text}
                  onBlur={(e) => dispatch({type: type, payload: e.target.value}) && setText(e.target.value)} />
}

export default TextSearchInput;