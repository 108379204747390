import React, {useState} from "react";
import styled from "styled-components";
import {themes} from "../../../layout/styles/ColorStyles";
import useUpdateUserInfo from "../../../hooks/users/useUpdateUserInfo";
import UserForm from "../../Forms/Users/UserForm";

const Button = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: ${themes.editButton};
  cursor: pointer;
`;
const EditUserModal = ({ user, reload }) => {
  const [open, setIsOpen] = useState(false);
  const { update } = useUpdateUserInfo();

  const handleOk = (values) => {
    let obj = {
      id: user.id,
      ...values,
    };
    update(obj).then(() => {
      reload();
      setIsOpen(false);
    });
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Edytuj</Button>
      <UserForm
        title={"Edytuj użytkownika"}
        handleOk={handleOk}
        onCancel={() => setIsOpen(false)}
        open={open}
        user={user}
      />
    </>
  );
};

export default EditUserModal;
