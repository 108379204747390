import Lottie from "react-lottie";
import aniomationData from "../../files/indicator.json";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const WrapperLottie = styled.div`
  height: 130px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e5e9f2;
`;

const FormIndicator = ({ numberOfElements = 1 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: aniomationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const loading = [...Array(numberOfElements)].map((it, index) => {
    return (
      <WrapperLottie key={index}>
        <Lottie options={defaultOptions} height={"100%"} width={"20%"} />
      </WrapperLottie>
    );
  });

  return <Wrapper>{loading}</Wrapper>;
};

export default FormIndicator;
