import { themes } from "../../../layout/styles/ColorStyles";
import { EditOutlined } from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import { useState } from "react";
import useUpdateOrderAddress from "../../../hooks/orders/useUpdateOrderAddress";

const UpdateOrderAddress = ({ orderAddress, id, reload }) => {
  const [visible, setVisible] = useState(false);
  const { update } = useUpdateOrderAddress();

  const onCreate = (values) => {
    update({ orderId: id, ...values }).then(() => reload());
    setVisible(false);
  };
  return (
    <>
      <EditOutlined
        style={{
          color: themes.activeMenuItemColor,
          fontSize: "20px",
        }}
        onClick={() => setVisible(true)}
      />
      <CollectionCreateForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
        orderAddress={orderAddress}
      />
    </>
  );
};

export default UpdateOrderAddress;

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  orderAddress,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Adres do wysyłki"
      okText="Zapisz zmiany"
      cancelText="Anuluj"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          name: orderAddress.name,
          mobilePhone: orderAddress.mobilePhone,
          street: orderAddress.street,
          localNumber: orderAddress.localNumber,
          city: orderAddress.city,
          postCode: orderAddress.postCode,
        }}
      >
        <Form.Item
          name="name"
          label="Imie i Nazwisko"
          rules={[
            {
              required: true,
              message: "Proszę uzupełnić imię i nazwisko",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mobilePhone"
          label="Numer telefonu"
          rules={[
            {
              required: true,
              message: "Proszę uzupełnić numer telefonu",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="street"
          label="Ulica"
          rules={[
            {
              required: true,
              message: "Proszę uzupełnić ulicę",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="localNumber"
          label="Numer domu/mieszkania"
          rules={[
            {
              required: true,
              message: "Proszę uzupełnić numer domu/mieszkania",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="city"
          label="Miasto"
          rules={[
            {
              required: true,
              message: "Proszę uzupełnić miasto",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="postCode"
          label="Kod pocztowy"
          rules={[
            {
              required: true,
              message: "Proszę uzupełnić kod pocztowy",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
