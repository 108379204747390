import axios from "axios";
import authStorage from "./authStorage";

const BASE_URL = process.env.REACT_APP_API_URL;

// Create axios instance.
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  //   withCredentials: true,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = authStorage.getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";

  return config;
});

export default axiosInstance;
