import {useState} from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useAddProductVariantPhoto = () => {

    const [isLoading, setisLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async (data: FormData, id: string): Promise<any> => {
        try {
            setisLoading(true);
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            await axiosInstance.post(
                `/partner/product/variant/add-photo/${id}`,
                data,
                config
            );

            successMessage("Twoje zdjecie zostanie wkrótce dodane do produktu");
        } catch (err) {
            errorMessage("Błąd dodawania zdjęcia, skontaktuj się z administratorem. ");
            setisLoading(false);
            throw err;
        }
    };

    return { update, isLoading };
}

export default useAddProductVariantPhoto;