import useGetPartnerUsers from "../../hooks/users/useGetPartnerUsers";
import {Breadcrumb} from "antd";
import FormIndicator from "../../components/Forms/FormIndicator";
import UserTable from "../../components/Tables/UserTable";
import NewUserModal from "../../components/Modals/Users/NewUserModal";

const Users = () => {
  const { data, isLoading, reload } = useGetPartnerUsers();
  return (
    <>
      <Breadcrumb style={{ paddingBottom: "20px" }}>
        <Breadcrumb.Item>Ustawienia konta</Breadcrumb.Item>
        <Breadcrumb.Item>Użytkownicy</Breadcrumb.Item>
      </Breadcrumb>
      <NewUserModal reload={reload}/>
      {!isLoading ? (
        <>
          <UserTable data={data} reload={reload} />
        </>
      ) : (
        <FormIndicator numberOfElements={3} />
      )}
    </>
  );
};

export default Users;
