import React, {useState} from "react";
import {Input, Modal, Typography} from "antd";

const {Text} = Typography;

const OrderDeliveryNumberInput = ({
                                      row,
                                      isEditable = true,
                                      handleOrderDeliveryNumberChange,

                                  }) => {

    const [isEdit, setIsEdit] = React.useState(isEditable);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [orderDeliveryNumber, setOrderDeliveryNumber] = useState(row.deliverView.trackingNumber);

    const showModal = (value) => {
        setIsModalVisible(true);
        setOrderDeliveryNumber(value);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setIsEdit(false)
        handleOrderDeliveryNumberChange(orderDeliveryNumber);
    };
    return (
        <>
            <Input
                placeholder={row.deliverView.trackingNumber}
                disabled={!isEdit}
                onBlur={(e) => showModal(e.target.value)}
            />
            <Modal title="Potwierdź zmiany" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} cancelText={'Anuluj'} okText={'Potwierdź'}>
                <><Text strong>
                    Czy na pewno chcesz dodać numer przesyłki?
                </Text></>
            </Modal>
        </>
    );
};

export default OrderDeliveryNumberInput;
