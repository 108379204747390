import React, { useReducer } from "react";
import {
  initialState,
  ProductVariantReducer,
} from "../reducers/ProductVariantReducer";

const ProductVariantContext = React.createContext();

export const ProductVariantProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductVariantReducer, initialState);

  return (
    <ProductVariantContext.Provider value={{ state, dispatch }}>
      {children}
    </ProductVariantContext.Provider>
  );
};

export default ProductVariantContext;
