import { toast } from "react-toastify";

const useAlert = () => {
  const errorMessage = (message) => {
    return toast.error(message, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successMessage = (message) => {
    return toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return { errorMessage, successMessage };
};

export default useAlert;
