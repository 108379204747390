import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateProductVariantPriceLockStatusInterface {
    productVariantId: string;
    stock: number;
    isLocked: boolean;
}

const useUpdateProductVariantLockStock = () => {

    const { successMessage, errorMessage } = useAlert();

    const update = async (
        obj: UpdateProductVariantPriceLockStatusInterface
    ): Promise<any> => {
        try {
            await axiosInstance.put(
                "/partner/product/variant/" + obj.productVariantId + "/stock-lock-status",
                obj
            );
            successMessage("Informację zostały zaaktualizowane");
        } catch (err) {
            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return { update };

}

export default useUpdateProductVariantLockStock;