import {Button, Form,} from "antd";
import styled from "styled-components";
import React, {useContext} from "react";
import OrderFiltersContext from "../../../../context/OrderFiltersContext";
import {
    CLEAR_ALL,
    UPDATE_DATE_FROM,
    UPDATE_DATE_TO,
    UPDATE_ORDER_NUMBER
} from "../../../../reducers/OrderFiltersReducers";
import OrderNumberSearch from "./OrderNumberSearch";
import DatePickerFrom from "./DatePickerFrom";
import PriceInput from "./PriceInput";
import OrderStatusSelect from "./OrderStatusSelect";
import ClientSearch from "./ClientSearch";
import TrackingNumberSerach from "./TrackingNumberSerach";
import OrderPaymentStatus from "./OrderPaymentStatus";
import OrderDeliverySelect from "./OrderDeliverySelect";
import DatePrickerTo from "./DatePrickerTo";

const Content = styled(Form)`
  display: grid;
  width: 100%;
  height: 100%;
  column-gap: 10px;
  grid-template-columns: 25% 24% 25% 25%;
`;

const FilterOrderList = ({ deliveries = [], reload }) => {
  const { dispatch, state } = useContext(OrderFiltersContext);
  const [form] = Form.useForm();

  const onFinish = () => {
      reload(state);
  };

  const handleReset = () => {
    form.resetFields();
    dispatch({ type: CLEAR_ALL});
  };

  return (
    <Content form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
      <Form.Item label="Numer zamówienia" name="orderNumber">
        <OrderNumberSearch type={UPDATE_ORDER_NUMBER} context={OrderFiltersContext}/>
      </Form.Item>
      <Form.Item label="Data utworzenia od" name="dateFrom">
        <DatePickerFrom type={UPDATE_DATE_FROM} context={OrderFiltersContext}/>
      </Form.Item>
        <Form.Item label="Data utworzenia do" name="dateTo">
            <DatePrickerTo type={UPDATE_DATE_TO} context={OrderFiltersContext}/>
        </Form.Item>
      <Form.Item label="Kwota zamówienia">
        <PriceInput />
      </Form.Item>
      <Form.Item label="Status zamówienia" name="status" initialValue={state ? state.status : ''}>
          <OrderStatusSelect />
      </Form.Item>
      <Form.Item label="Klient(nazwisko lub email)" name="client">
        <ClientSearch />
      </Form.Item>
      <Form.Item label="Numer śledzenia przesyłki" name="trackingNumber">
        <TrackingNumberSerach />
      </Form.Item>
      <Form.Item label="Status płatności" name="paymentStatus">
        <OrderPaymentStatus />
      </Form.Item>
      <Form.Item key="deliveryId" name="deliveryId" label="Dostawca">
        <OrderDeliverySelect deliveries={deliveries}/>
      </Form.Item>
      <Form.Item>
        <Button
          style={{ marginRight: "10px", marginTop: '40px' }}
          type="primary"
          htmlType="submit"
        >
          Zastosuj
        </Button>
        <Button htmlType="button" onClick={() => handleReset()}>
          Wyczyść filtry
        </Button>
      </Form.Item>
    </Content>
  );
};

export default FilterOrderList;
