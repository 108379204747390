import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";
import productOwnerStorage from "../../lib/productOwnerStorage";

const useGetProductOwnerInfo = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async (): Promise<any> => {
    try {
      const res = await axiosInstance.get(`/partner/product_owner/`);
      if (res.status === 200) {
        setData(res.data ? res.data : []);
        productOwnerStorage.storeProductOwnerName(res.data.code);
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err");
      setIsLoading(false);
    }
  };

  const reload = () => {
    getInfo();
  };

  return { isLoading, data, reload };
};

export default useGetProductOwnerInfo;
