import {useState} from "react";
import useUploadInvoiceOrder from "../../../hooks/orders/useUploadInvoiceOrder";
import {useParams} from "react-router-dom";
import {Button} from "antd";
import {DownloadOutlined} from "@ant-design/icons";

const UploadOrderBilling = ({invoice}) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const {update} = useUploadInvoiceOrder();
    const params = useParams();

    const onFileChange = (event) => {
        // Update the state
        setSelectedFile(event.target.files[0]);
    };

    // On file upload (click the upload button)
    const onFileUpload = () => {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );

        // Request made to the backend api
        // Send formData object
        update(formData, params.id);
    };

    return <>{
        invoice ? <Button style={{marginRight: '10px'}} icon={<DownloadOutlined/>} size={'middle'}><a target="_blank"  rel="noreferrer"
                                                                                                      href={invoice.fullUrl}>Pobierz
            fakturę</a></Button> : ''}
        <input type="file" onChange={onFileChange}/>
        <Button type='primary' onClick={onFileUpload}>
            Zapisz
        </Button>
    </>;
};

export default UploadOrderBilling;
