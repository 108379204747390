import {Link, useParams} from "react-router-dom";
import styled from "styled-components";
import useGetOrderById from "../../hooks/orders/useGetOrderById";
import {useEffect} from "react";
import FormIndicator from "../../components/Forms/FormIndicator";
import {Breadcrumb, Card, Image} from "antd";
import useChangeOrderStatus from "../../hooks/orders/useChangeOrderStatus";
import StatusDropdown from "../../components/Dropdown/Orders/StatusDropdown";
import UpdateOrderAddress from "../../components/Modals/Order/UpdateOrderAddress";
import OrderDeliveryNumberInput from "../../components/Input/Orders/OrderDeliveryNumberInput";
import useOrderDeliveryUpdateTrackingNumber from "../../hooks/orders/useOrderDeliveryUpdateTrackingNumber";
import OrderItemsTable from "../../components/Tables/Orders/OrderItemsTable";
import PaymentStatusLabel from "../../components/Labels/PaymentStatusLabel";
import LogoPayment from "../../components/Image/LogoPayment";
import UploadOrderBilling from "../../components/Uploader/Order/UploadOrderBilling";

const Wrapper = styled.div``;
const OrderDetailComponent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
const OrderInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
`;

const OrderMainInfoComponent = styled.div`
  flex: 2;
  background-color: white;
`;

const OrderDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  border-top: 1px solid #f0f0f0;
`;

const OrderDetailRowLeft = styled.div`
  width: 25%;
  background-color: #fafafa;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
  border-right: 1px solid #f0f0f0;
`;

const OrderDetailRowRight = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
  gap: 8px
`;

const OrderDetail = () => {
    const params = useParams();
    const {data, getOrder, isLoading} = useGetOrderById();

    useEffect(() => {
        getOrder(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {update} = useChangeOrderStatus();
    const {update: updateDeliveryNumber} =
        useOrderDeliveryUpdateTrackingNumber();

    const handleStatusChange = (id, status) => {
        update({status, orderId: id}).then(() => getOrder(id));
    };

    const handleUpdateDeliveryNumber = (id, deliveryNumber) => {
        updateDeliveryNumber({trackingNumber: deliveryNumber, orderId: id})
            .then(
            () => {
                if (data.status === 'created' || data.status === 'submitted') {
                    handleStatusChange(id, 'packaged');
                } else {
                    getOrder(id)
                }
            }
        );
    };
    return (
        <>
            {isLoading ? (
                <FormIndicator numberOfElements={3}/>
            ) : (
                <Wrapper>
                    <Breadcrumb style={{paddingBottom: "24px"}}>
                        <Breadcrumb.Item>Panel Sprzedawcy</Breadcrumb.Item>
                        <Breadcrumb.Item>Zamówienia</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={'/orders'}>Lista zamówień</Link></Breadcrumb.Item>

                        <Breadcrumb.Item>Zamówienie {data.orderNumber}</Breadcrumb.Item>
                    </Breadcrumb>

                    <OrderInfo>
                        <div style={{display: 'flex', gap: '4px'}}>
                            Zamówienie numer: <div style={{fontWeight: 600}}>{data.orderNumber}</div>
                        </div>
                    </OrderInfo>

                    <OrderDetailComponent>
                        <OrderMainInfoComponent>
                            <OrderDetailRow>
                                <OrderDetailRowLeft>Status Zamówienia</OrderDetailRowLeft>
                                <OrderDetailRowRight>
                                    <StatusDropdown
                                        row={data}
                                        handleMenuClick={(e) => handleStatusChange(data.orderId, e)}
                                    />
                                </OrderDetailRowRight>
                            </OrderDetailRow>
                            <div style={{padding: "10px"}}>
                                <OrderItemsTable orderItems={data.items} data={data}/>
                            </div>
                            <OrderDetailRow>
                                <OrderDetailRowLeft>
                                    Data złożenia zamówienia
                                </OrderDetailRowLeft>
                                <OrderDetailRowRight>{data.createdAt}</OrderDetailRowRight>
                            </OrderDetailRow>
                            <OrderDetailRow>
                                <OrderDetailRowLeft>Status płatności</OrderDetailRowLeft>
                                <OrderDetailRowRight>
                                    <PaymentStatusLabel paid={data.orderPaymentView.paid}/>
                                </OrderDetailRowRight>
                            </OrderDetailRow>
                            <OrderDetailRow>
                                <OrderDetailRowLeft>Forma płatności</OrderDetailRowLeft>
                                <OrderDetailRowRight>
                                    <LogoPayment src={data.orderPaymentView.paymentMethod.logo}/>
                                    {data.orderPaymentView.paymentMethod.name} (Transakcja
                                    nr {data.orderPaymentView.externalPaymentId} ) | {data.orderPaymentView.payAt}
                                </OrderDetailRowRight>
                            </OrderDetailRow>
                        </OrderMainInfoComponent>

                        <div style={{display: 'flex', flexDirection: 'column', flex: 1, gap: '10px'}}>
                            <Card
                                type="inner"
                                title="Adres do wysyłki"
                                extra={
                                    <UpdateOrderAddress
                                        orderAddress={data.address}
                                        id={params.id}
                                        reload={() => getOrder(params.id)}
                                    />
                                }
                            >
                                {data.address.name}
                                <br/>
                                ul. {data.address.street} {data.address.localNumber}
                                <br/>
                                {data.address.postCode} {data.address.city}
                                <br/>
                                tel: {data.address ? data.address.mobilePhone : "Brak"}
                                <br/>
                                {(data.productOwner.productOwnerConfigurationView && data.productOwner.productOwnerConfigurationView.emailShown)
                                    ?
                                    <div>email: {data.address.email}</div>
                                    : null}

                            </Card>
                            <Card type="inner" title="Metoda dostawy">
                                {data.deliverView.deliveryProductOwner.deliveryView.logo ? (
                                    <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                        <Image
                                            preview={false}
                                            src={
                                                data.deliverView.deliveryProductOwner.deliveryView.logo
                                            }
                                            width={50}
                                            height={30}
                                        />
                                        <div>{data.deliverView.deliveryProductOwner.deliveryView.name}</div>
                                    </div>
                                ) : (
                                    data.deliverView.deliveryProductOwner.deliveryView.name
                                )}
                                <div>{data.deliverView.notes ? 'Punkt odbioru ' + data.deliverView.notes : ''}</div>
                            </Card>
                            <Card type="inner" title="Numer śledzenia przesyłki">
                                <OrderDeliveryNumberInput
                                    isEditable={!data.deliverView.trackingNumber}
                                    row={data}
                                    handleOrderDeliveryNumberChange={(e) =>
                                        handleUpdateDeliveryNumber(data.orderId, e)
                                    }
                                />
                            </Card>
                            {data.billingAddressView ? (
                                <>
                                    <Card
                                        type="inner"
                                        title="Adres do faktury"

                                    >
                                        {data.billingAddressView.name}
                                        <br/>
                                        NIP: {data.billingAddressView.taxNumber}
                                        <br/>
                                        ul. {data.billingAddressView.street}{" "}
                                        {data.billingAddressView.localNumber}
                                        <br/>
                                        {data.billingAddressView.postCode}{" "}
                                        {data.billingAddressView.city}
                                    </Card>
                                </>
                            ) : null}
                            <Card type="inner" title={!data.billingAddressView ? "Załącz paragon" : 'Załącz fakturę'}>
                                <UploadOrderBilling invoice={data.orderInvoiceView}/>
                            </Card>
                            <Card type="inner" title='Notatka od kupującego'>
                                {data.userNote ? data.userNote : '-'}
                            </Card>
                            {/*<Card type="inner" title="Historia zamówienia">*/}
                            {/*  <Timeline>*/}
                            {/*    <Timeline.Item>*/}
                            {/*      Create a services site 2015-09-01*/}
                            {/*    </Timeline.Item>*/}
                            {/*    <Timeline.Item>*/}
                            {/*      Solve initial network problems 2015-09-01*/}
                            {/*    </Timeline.Item>*/}
                            {/*    <Timeline.Item>Technical testing 2015-09-01</Timeline.Item>*/}
                            {/*    <Timeline.Item>*/}
                            {/*      Network problems being solved 2015-09-01*/}
                            {/*    </Timeline.Item>*/}
                            {/*  </Timeline>*/}
                            {/*</Card>*/}
                        </div>
                    </OrderDetailComponent>
                </Wrapper>
            )}
        </>
    );
};

export default OrderDetail;
