import LogoDelivery from "../../../Image/LogoDelivery";
import {Select} from "antd";
import React, {useContext} from "react";
import OrderFiltersContext from "../../../../context/OrderFiltersContext";
import {UPDATE_DELIVERY_ID} from "../../../../reducers/OrderFiltersReducers";

const {Option} = Select;

const OrderDeliverySelect = ({deliveries}) => {

    const {dispatch, state} = useContext(OrderFiltersContext);

    return <Select
        showSearch
        placeholder="Wybierz dostawce"
        filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        allowClear
        onChange={(e) => dispatch({type: UPDATE_DELIVERY_ID, payload: e})}
        defaultValue={state.deliveryId}
    >
        {deliveries.map((it) => (
            <Option key={it.id} value={it.id}>
                <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                    <LogoDelivery src={it.logo}/>
                    <div>{it.name}</div>
                </div>
            </Option>
        ))}
    </Select>
}

export default OrderDeliverySelect;