import { Image, Table, Typography } from "antd";
import useGetRelatedProductVariants from "../../../hooks/productVariants/useGetRelatedProductVariants";
import { useParams } from "react-router-dom";
import FormIndicator from "../../Forms/FormIndicator";
import React, { useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 0;
`;

const columns = [
  {
    title: "Produkt",
    dataIndex: "",
    key: "icon",
    width: "30%",
    render: (row) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          <Image
            width={52}
            height={52}
            preview={true}
            src={
              row.photo.length && typeof row.photo[0].fullPath !== "undefined"
                ? row.photo[0].fullPath
                : null
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div>
              <div style={{ fontWeight: "600" }}>{row.name}</div>
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: "Id Produktu",
    dataIndex: "",
    key: "id",
    render: (row) => <a href={"/product-variant/" + row.id}>{row.id}</a>,
    // render: (row) => <Link to={"/product-variant/" + row.id}>{row.id}</Link>,
  },
  {
    title: "Wariant",
    dataIndex: "",
    key: "variant",
    render: (row) => (
      <>
        {row.grammage && row.variantValue ? (
          <>
            {row.variantValue}
            {row.grammage}
          </>
        ) : (
          "-"
        )}
      </>
    ),
  },
  {
    title: "Cena Brutto",
    dataIndex: "price",
    key: "price",
    render: (row) => (row.length ? row[0].priceGross + " zł" : "Brak ceny"),
  },
];

const ReleatedProductsVariantsTab = () => {
  const params = useParams();
  const { data, isLoading, getProductVariant } = useGetRelatedProductVariants();

  useEffect(() => {
    getProductVariant(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {isLoading ? (
        <FormIndicator numberOfElements={1} />
      ) : (
        <>
          <Typography.Text>Inne warianty tego produktu</Typography.Text>
          <Table
            style={{ marginTop: "10px" }}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </>
      )}
    </Wrapper>
  );
};

export default ReleatedProductsVariantsTab;
