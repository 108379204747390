import React from "react";
import styled from "styled-components";
import {themes} from "./styles/ColorStyles";
import {GlobalStyle} from "./styles/GlobalStyle";
import LeftMenu from "./LeftMenu";
import TopMenu from "./TopMenu";

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-columns: 300px auto;
`;

const LeftMenuWrapper = styled.div`
  background-color: #021529;
  border-right: 1px solid #e5e9f2;
  box-shadow: 0px 1px 3px 0px rgb(54 74 99 / 5%);
`;

const TopMenuWrapper = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #e5e9f2;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: 70px auto;
  background-color: ${themes.light.backgroundColor} !important;
`;

const BodyContent = styled.div`
  padding: 20px;
`;

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <LeftMenuWrapper>
          <LeftMenu />
        </LeftMenuWrapper>
        <ContentWrapper>
          <TopMenuWrapper>
            <TopMenu />
          </TopMenuWrapper>
          <BodyContent>
            <main>{children}</main>
          </BodyContent>
        </ContentWrapper>
      </Wrapper>
    </>
  );
};

export default Layout;
