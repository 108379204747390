import { useState } from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateOrderStatus {
  orderId: string;
  status: string;
}

const useChangeOrderStatus = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { successMessage, errorMessage } = useAlert();

  const update = async (obj: UpdateOrderStatus): Promise<any> => {
    try {
      setisLoading(true);
      await axiosInstance.put("/partner/order/status/" + obj.orderId, obj);
      successMessage("Status został zmieniony");
    } catch (err) {
      setisLoading(false);
      errorMessage("Błąd zapisu. Spróbuj ponownie.");
      throw err;
    }
  };

  return { update, isLoading };
};

export default useChangeOrderStatus;
