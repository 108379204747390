import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import {Image, Spin} from "antd";
import {Link} from "react-router-dom";
import {ButtonSelected, translation} from "../Dropdown/Orders/StatusDropdown";

const DropDown = styled.div`
  //border: 1px solid #e6e6e6;
  position: relative;
  width: 90%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const Options = styled(DropDown)`
  display: ${(props) => (props.value ? "block" : "none")};
  margin-top: 1px;
  height: ${(props) => {
    switch (props.length) {
      case 0:
        return "70px";
      case 1:
        return "70px";
      case 2:
        return "140px";
      case props.length > 2:
        return "210px";
      default:
        return "70px";
    }
  }
  };
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  box-shadow: 5px 10px 18px #888888;
`;

const Option = styled(DropDown)`
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  height: 70px;
  width: 100%;
  background-color: white;

  :hover {
    background-color: #F7F7F7;
  }
`;

const Input = styled.input`
  padding: 0.5em;
  color: #929393;
  background: white;
  border: none;
  height: 100%;
  font-size: 17px;
  width: 100%;
`;
const SearchTopMenu = ({handleChange, orders, isLoading }) => {

    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener("click", close);
        return () => document.removeEventListener("click", close);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function close(e) {
        setOpen(e && e.target === ref.current);
    }


    return <DropDown>
        <SearchOutlined
            style={{fontSize: "17px", marginLeft: "10px", marginRight: "10px"}}
        />
        <Input
            onClick={() => setOpen((prev) => !prev)}
            placeholder="Szukaj zamówienia..."
            type="text"
            inputColor="rebeccapurple"
            ref={ref}
            onChange={handleChange}
        />
        {(isLoading) ? (
            <Options value={open} key="options">
                <Option key='spin'>
                    <Spin size={"large"}/>
                </Option>
            </Options>
        ) : (
            <Options key="options" value={open} length={orders.length}>
                {orders.length ?
                    orders.map((order, index) => (
                        <Link to={'/order/' + order.orderId}>
                            <Option key={index}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{color: 'black'}}>{order.createdAt}</div>
                                        {/*<div style={{color: rgba(51, 51, 51, 0.8)}}>{order.time}</div>*/}
                                    </div>
                                    <div style={{flex: "1"}}>{order.orderNumber}</div>
                                    <div style={{flex: "1"}}>
                                        <ButtonSelected row={order.status}>
                                            {translation(order.status)}
                                        </ButtonSelected>
                                    </div>
                                    <div style={{color: 'black', flex: "1", marginLeft: '8px'}}>
                                        {order.user
                                            ? order.user.name + " " + order.user.lastName
                                            : "Brak danych"}
                                    </div>
                                    <div style={{flex: "1", color: 'black'}}>
                                        {order.deliverView.deliveryProductOwner.deliveryView.logo ? (
                                            <Image
                                                style={{width: '50px', height: '39px', objectFit: 'contain'}}
                                                src={order.deliverView.deliveryProductOwner.deliveryView.logo}/>
                                        ) : (
                                            order.deliverView.deliveryProductOwner.deliveryView.name
                                        )}
                                    </div>
                                    <div style={{flex: "1", color: 'black'}}>
                                        {order.totalItemPriceGross} zł{" "}
                                    </div>
                                </div>
                            </Option>
                        </Link>
                    )) : <Option key='no_data'> Brak wyników odpowiadających wyszukiwanej frazie</Option>}
            </Options>
        )}
    </DropDown>
};

export default SearchTopMenu;