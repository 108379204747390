import {Input, Modal, Switch, Typography} from "antd";
import {themes} from "../../../layout/styles/ColorStyles";
import React, {useContext, useState} from "react";
import useUpdateProductVariantPriceLockStatus
    from "../../../hooks/productVariants/useUpdateProductVariantPriceLockStatus";
import {useParams} from "react-router-dom";
import {CheckOutlined, CloseOutlined,} from "@ant-design/icons";
import ProductVariantContext from "../../../context/ProductVariantContext";
import {SET_PRICE} from "../../../reducers/ProductVariantReducer";

const {Text} = Typography;

const ProductVariantMainSettingPriceInput = ({data, reload}) => {
    const params = useParams();
    const [isLocked, setIsLocked] = useState(data.price[0].locked);
    const [price, setPrice] = useState(data.price[0].priceGross);
    const {update} = useUpdateProductVariantPriceLockStatus();
    const {dispatch} = useContext(ProductVariantContext);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (value) => {
        setIsModalVisible(true);
        setPrice(value);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        dispatch({type: SET_PRICE, payload: price});
        update({
            productVariantId: params.id,
            priceGross: price,
            isLocked: isLocked,
        })
            .then(() => reload(params.id))
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = (e) => {
        setIsLocked(e);
        if (!e) {
            update({
                productVariantId: params.id,
                priceGross: price,
                isLocked: e,
            }).then(() => reload(params.id));
        }
    };

    return (
        <>
            <Text>Cena brutto</Text>
            <Modal title="Potwierdź zmiany" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <><Text strong>
                    Czy na pewno chcesz zmienić cenę i zablokować to pole w
                    synchronizacji danych?{" "}
                </Text>
                  <Text type="secondary">
                    {" "}
                    Spowoduje to ustawienie sztywnej ceny ręcznie i brak aktualizacji
                    podczas synchronizacji danych.
                </Text></>
            </Modal>
            <div style={{display: "flex", gap: "8px"}}>
                <Switch
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                    checked={isLocked}
                    onChange={(e) => handleChange(e)}
                    style={{
                        display: "flex",
                        width: "20px",
                        backgroundColor: isLocked
                            ? themes.activeMenuItemColor
                            : themes.switchInActive,
                    }}
                />
                <Text>Edytuj cenę ręcznie i zablokuj synchronizację z bazą danych</Text>
            </div>
            {!isLocked ? (
                <Text strong>{data.price[0].priceGross} zł</Text>
            ) : (
                <Input
                    type="number"
                    defaultValue={data.price[0].priceGross}
                    addonAfter="zł"
                    style={{width: "200px", marginBottom: "4px"}}
                    onBlur={(e) => showModal(e.target.value)}
                />
            )}
        </>
    );
};

export default ProductVariantMainSettingPriceInput;
