import {Table, Typography} from "antd";
import React from "react";
import ProductListTooltip from "../../Tooltips/Orders/ProductListTooltip";
import {Link} from "react-router-dom";
import StatusDropdown from "../../Dropdown/Orders/StatusDropdown";
import useChangeOrderStatus from "../../../hooks/orders/useChangeOrderStatus";
import OrderDeliveryNumberInput from "../../Input/Orders/OrderDeliveryNumberInput";
import DeliveryDetailsTooltip from "../../Tooltips/Orders/DeliveryDetailsTooltip";
import useOrderDeliveryUpdateTrackingNumber from "../../../hooks/orders/useOrderDeliveryUpdateTrackingNumber";
import useGetOrderCounter from "../../../hooks/orders/useGetOrderCounter";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";

const OrderTable = ({data, reload}) => {
    const {update} = useChangeOrderStatus();
    const {update: updateTrackingNumber} =
        useOrderDeliveryUpdateTrackingNumber();
    const {getCountOrder} = useGetOrderCounter();

    const handleStatusChange = (id, status) => {
        update({status, orderId: id}).then(() => {
            reload();
            getCountOrder();
        });
    };

    const handleOrderDeliveryNumberChange = (id, e) => {
        if (e) {
            updateTrackingNumber({
                orderId: id,
                trackingNumber: e,
            })  .then(
                () => {
                    if (data.status === 'created' || data.status === 'submitted') {
                        handleStatusChange(id, 'packaged');
                    } else {
                        reload(id)
                    }
                }
            );
        }
    };

    const columns = [
        {
            title: "Data",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (row) => <>{row}</>,
            width: "10%",
        },
        {
            title: "Numer zamówienia",
            dataIndex: "",
            key: "",
            render: (row) => (
                <Link to={"/order/" + row.orderId}>
                    <ProductListTooltip products={row.items} text={row.orderNumber}/>
                </Link>
            ),
            width: "10%",
        },
        {
            title: "Status",
            dataIndex: "",
            key: "status",
            render: (row) => (
                <StatusDropdown
                    row={row}
                    handleMenuClick={(e) => handleStatusChange(row.orderId, e)}
                />
            ),
            width: "10%",
        },
        {
            title: "Klient",
            dataIndex: "",
            key: "user",
            render: (row) =>
                row ? <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography>{row.address.name}</Typography>
                    {(row.productOwner.productOwnerConfigurationView && row.productOwner.productOwnerConfigurationView.emailShown)
                        ? <Typography>Email: {row.address.email}</Typography> : null}
                    <Typography>Tel: {row.address.mobilePhone}</Typography>
            </div> : "brak danych",
            width: "10%",
        },
        {
            title: "Płatność",
            dataIndex: "orderPaymentView",
            key: "",
            render: (row) => <PaymentStatusLabel paid={row.paid}/>,
            width: "10%",
        },
        {
            title: "Numer śledzenia przesyłki",
            dataIndex: "",
            key: "",
            render: (row) => {
                return (
                    <OrderDeliveryNumberInput
                        row={row}
                        isEditable={!row.deliverView.trackingNumber}
                        handleOrderDeliveryNumberChange={(e) =>
                            handleOrderDeliveryNumberChange(row.orderId, e)
                        }
                    />
                );
            },
            width: "22%",
        },
        {
            title: "Dostawa",
            dataIndex: "",
            key: "",
            render: (row) => <DeliveryDetailsTooltip delivery={row}/>,
            width: "8%",
            algin: 'center',
        },
        {
            title: "Kwota",
            dataIndex: "totalPriceGross",
            key: "totalPriceGross",
            width: "10%",
            render: (row) => <>{row} zł</>,
        },
        {
            title: "Akcja",
            dataIndex: "",
            key: "edit",
            render: (row) => <Link to={"/order/" + row.orderId}>Szczegóły</Link>,
        },
    ];

    return <Table columns={columns} dataSource={data} pagination={false}/>;
};

export default OrderTable;
