import { useState } from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateOrderAddressInterface {
  orderId: string;
  name: string;
  mobilePhone: string;
  street: string;
  localNumber: string;
  city: string;
  postCode: string;
}

const useUpdateOrderAddress = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { successMessage, errorMessage } = useAlert();

  const update = async (obj: UpdateOrderAddressInterface): Promise<any> => {
    try {
      setisLoading(true);
      await axiosInstance.put("/partner/order/address/" + obj.orderId, obj);
      successMessage("Adres wysłki został zmieniony");
    } catch (err) {
      setisLoading(false);
      errorMessage("Błąd zapisu. Spróbuj ponownie.");
      throw err;
    }
  };

  return { update, isLoading };
};

export default useUpdateOrderAddress;
