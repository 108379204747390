import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateProductVariantPhotoLockInterface {
  productVariantId: string;
  isLocked: boolean;
}

const useUpdateLockingProductVariantPhoto = () => {
  const { successMessage, errorMessage } = useAlert();

  const update = async (
    obj: UpdateProductVariantPhotoLockInterface
  ): Promise<any> => {
    try {
      await axiosInstance.put(
        "/partner/product/variant/" + obj.productVariantId + "/photo-lock",
        obj
      );
      successMessage("Informację zostały zaaktualizowane");
    } catch (err) {
      errorMessage("Błąd zapisu. Spróbuj ponownie.");
      throw err;
    }
  };

  return { update };
};

export default useUpdateLockingProductVariantPhoto;
