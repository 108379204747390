import { useState } from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

interface UpdateDeliveryFee {
  productOwnerId: string;
  price: string;
  deliveryId: string;
  isFreeFromPrice: boolean;
  freeFromPrice: null;
  maxTimeSendSameDay: string;
  deliveryDurationTime: number;
  isDeliveryNextDay: boolean;
}

const useAssignDeliveryToProductOwner = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { successMessage, errorMessage } = useAlert();

  const assign = async (obj: UpdateDeliveryFee): Promise<any> => {
    try {
      setisLoading(true);
      await axiosInstance.put("/partner/delivery/assigment/", obj);
      successMessage("Dodano nową metodę dostawy");
    } catch (err) {
      setisLoading(false);
      errorMessage("Błąd zapisu, spróbuj ponownie.");
      throw err;
    }
  };

  return { assign, isLoading };
};

export default useAssignDeliveryToProductOwner;
