import {themes} from "../../../layout/styles/ColorStyles";
import {Button, Dropdown, Menu, Modal, Typography} from "antd";
import styled from "styled-components";
import {rgba} from "polished";
import status from "../../../const/status";
import React, {useState} from "react";

const CREATED = "created";
const SUBMITTED = "submitted";
const PACKAGED = "packaged";
const SEND = "send";
const COMPLETED = "completed";
const RETURNED_ACCEPTED = "returned_accepted";
const RETURNED_REJECTED = "returned_rejected";
const RETURNED_REFUNDED = "returned_refunded";
const CANCELED_BY_SELLER = "cancelled_by_seller";
const CANCELED_BY_CUSTOMER = "cancelled_by_customer";
const CANCELLED_NOT_PAID = 'cancelled_not_paid';
const PACKAGED_LOST = 'packaged_lost';
const COMPLAINT = 'complaint';


const MenuCss = styled(Menu)`
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 10px;
  padding: 10px;
`;

const {Text} = Typography;

export const ButtonSelected = styled(Button)`
  background-color: ${(props) => {
    switch (props.row) {
      case CREATED:
        return rgba(themes.status.created, 0.1);
      case CANCELED_BY_SELLER:
        return rgba(themes.status.canceled, 0.1);
      case CANCELED_BY_CUSTOMER:
        return rgba(themes.status.canceled, 0.1);
      case SUBMITTED:
        return rgba(themes.status.submitted, 0.1);
      case COMPLETED:
        return rgba(themes.status.completed, 0.1);
      case PACKAGED:
        return rgba(themes.status.packaged, 0.1);
      case RETURNED_ACCEPTED:
        return rgba(themes.status.returned, 0.1);
      case RETURNED_REJECTED:
        return rgba(themes.status.returned, 0.1);
      case RETURNED_REFUNDED:
        return rgba(themes.status.returned, 0.1);
      case SEND:
        return rgba(themes.status.send, 0.1);
      case CANCELLED_NOT_PAID:
        return rgba(themes.status.cancelled_not_paid, 0.1);
      case PACKAGED_LOST:
        return rgba(themes.status.packaged_lost, 0.1)
      case COMPLAINT:
        return rgba(themes.status.complaint, 0.1);

      default:
        return "#fbbd08";
    }
  }};
  border: 1px solid ${(props) => {
    switch (props.row) {
      case CREATED:
        return themes.status.created;
      case CANCELED_BY_SELLER:
        return themes.status.canceled;
      case CANCELED_BY_CUSTOMER:
        return themes.status.canceled;
      case SUBMITTED:
        return themes.status.submitted;
      case COMPLETED:
        return themes.status.completed;
      case PACKAGED:
        return themes.status.packaged;
      case RETURNED_ACCEPTED:
        return themes.status.returned;
      case RETURNED_REJECTED:
        return themes.status.returned;
      case RETURNED_REFUNDED:
        return themes.status.returned;
      case SEND:
        return themes.status.send;
      case CANCELLED_NOT_PAID:
        return themes.status.cancelled_not_paid;
      case COMPLAINT:
        return themes.status.complaint;
      case PACKAGED_LOST:
        return themes.status.packaged_lost;

      default:
        return "#fbbd08";
    }
  }};

  color: ${(props) => {
    switch (props.row) {
      case CREATED:
        return themes.status.created;
      case CANCELED_BY_SELLER:
        return themes.status.canceled;
      case CANCELED_BY_CUSTOMER:
        return themes.status.canceled;
      case SUBMITTED:
        return themes.status.submitted;
      case COMPLETED:
        return themes.status.completed;
      case PACKAGED:
        return themes.status.packaged;
      case RETURNED_ACCEPTED:
        return themes.status.returned;
      case RETURNED_REJECTED:
        return themes.status.returned;
      case RETURNED_REFUNDED:
        return themes.status.returned;
      case SEND:
        return themes.status.send;
      case CANCELLED_NOT_PAID:
        return themes.status.cancelled_not_paid
      case COMPLAINT:
        return themes.status.complaint;
      case PACKAGED_LOST:
        return themes.status.packaged_lost;

      default:
        return "#fbbd08";
    }
  }};
`;

export const translation = (status) => {
    switch (status) {
        case CREATED:
            return "Nowe zamówienie";
        case SUBMITTED:
            return "Przyjęte do realizacji";
        case PACKAGED:
            return "Spakowane";
        case SEND:
            return "Wysłane";
        case COMPLETED:
            return "Gotowe";
        case RETURNED_ACCEPTED:
            return "Zwrot - przyjęty";
        case RETURNED_REJECTED:
            return "Zwrot - odrzucony";
        case RETURNED_REFUNDED:
            return "Zwrot - rozpatrzony";
        case CANCELED_BY_CUSTOMER:
            return "Anulowanie przez kupującego";
        case CANCELED_BY_SELLER:
            return "Anulowanie przez sprzedającego";
        case CANCELLED_NOT_PAID:
            return "Anulowane brak opłaty"
        case COMPLAINT:
            return "Zgłoszenie uszkodzenia";
        case PACKAGED_LOST:
            return 'Paczka zgubiona'
        default:
            return "";
    }
}

const StatusDropdown = ({row, handleMenuClick}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [statusValue, setStatusValue] = useState();

    const showModal = (value) => {
        setIsModalVisible(true);
        setStatusValue(value);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        handleMenuClick(statusValue.value);
    };

    const menu = (
        <MenuCss>
            {status.map((it, index) => {
                if (it.value !== 'created' && it.value !== 'cancelled_not_paid') {
                    return (
                        <ButtonSelected onClick={() => showModal(it)} row={it.value} key={index}>
                            {it.label}
                        </ButtonSelected>
                    );
                }
            })}
        </MenuCss>
    );

    return (
        <>
            <Dropdown overlay={menu}>
                <ButtonSelected row={row.status}>
                    {status.map((it) => (it.value === row.status ? it.label : ""))}
                </ButtonSelected>
            </Dropdown>
            <Modal title="Potwierdź zmiany" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                   cancelText={'Anuluj'} okText={'Potwierdź'}>
                <><Text strong>
                    Czy na pewno chcesz zmienić status?
                </Text></>
            </Modal>
        </>
    );
};

export default StatusDropdown;
