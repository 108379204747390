import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateProductVariantNameDescirptionInterface {
  productVariantId: string;
  name: string;
  description: string;
  isLocked: boolean;
  isMultiSave: boolean;
}

const useUpdateLockingProductVariantDescription = () => {
  const { successMessage, errorMessage } = useAlert();
  const update = async (
    obj: UpdateProductVariantNameDescirptionInterface
  ): Promise<any> => {
    if (obj.isLocked) {
      try {
        await axiosInstance.put(
          "/partner/product/variant/" +
            obj.productVariantId +
            "/description/lock",
          obj
        );
        successMessage("Informację zostały zaaktualizowane");
      } catch (err) {
        errorMessage("Błąd zapisu. Spróbuj ponownie.");
        throw err;
      }
    } else {
      try {
        await axiosInstance.put(
          "/partner/product/variant/" +
            obj.productVariantId +
            "/description/unlock ",
          obj
        );
        successMessage("Informację zostały zaaktualizowane");
      } catch (err) {
        errorMessage("Błąd zapisu. Spróbuj ponownie.");
        throw err;
      }
    }
  };

  return { update };
};

export default useUpdateLockingProductVariantDescription;
