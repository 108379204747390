import {Tabs} from "antd";
import styled from "styled-components";
import {themes} from "../../../layout/styles/ColorStyles";
import MainSettingProductVariantTab from "./MainSettingProductVariantTab";
import ReleatedProductsVariantsTab from "./ReleatedProductsVariantsTab";
import ContentProductVariant from "./ContentProductVariant";
import MediaProductVariantTab from "./MediaProductVariantTab";

const { TabPane } = Tabs;
const ProductMainInfoComponent = styled.div`
  flex: 3;
  background-color: white;
`;

const Test = styled(Tabs)`
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${themes.activeMenuItemColor};
    pointer-events: none;
  }
  .ant-tabs-tab-active {
    font-weight: 700;
  }

  .ant-tabs-tab-btn {
    font-weight: 400;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${themes.activeMenuItemColor};
    text-shadow: 0 0 0.25px currentcolor;
  }
`;

const ProductVariantTabs = ({ data, reload }) => {
  return (
    <ProductMainInfoComponent>
      <Test
        tabBarStyle={{
          backgroundColor: "#fafafa",
          hover: {
            backgroundColor: "#fafafa",
          },
        }}
        tabPosition="left"
      >
        <TabPane tab="Ustawienia ogólne" key="1">
          <MainSettingProductVariantTab data={data} reload={reload} />
        </TabPane>
        <TabPane tab="Powiązania produktu" key="2">
          <ReleatedProductsVariantsTab />
        </TabPane>
        <TabPane tab="Treści" key="3">
          <ContentProductVariant data={data} />
        </TabPane>
        <TabPane tab="Media" key="4">
          <MediaProductVariantTab data={data} reload={reload}/>
        </TabPane>
      </Test>
    </ProductMainInfoComponent>
  );
};

export default ProductVariantTabs;
